import Link from 'next/link';
import { CarDiscBrakeRepair } from '../icons/CarDiscBrakeRepair';
import { CarHealth } from '../icons/CarHealth';
import { CarInsurance } from '../icons/CarInsurance';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import ViewAll from './ViewAll';
import { useTranslation } from 'next-i18next';
import { useAppSelector } from '../../lib/hooks';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useEffect } from 'react';
import { useTranslationContext } from '../../provider/TranslationProvider';
import { isMobileOnly } from 'react-device-detect';

const CarServices = () => {
  const { i18n } = useTranslation();
  const cleverTap = useAppSelector(({ global }) => global.clevertap);
  const router = useRouter();
  const { LabelConstants } = useTranslationContext();

  useEffect(() => {
    var swiperCarService = new Swiper(`.car-service-swiper`, {
      spaceBetween: 24,
      slidesPerView: 4,
      modules: [Navigation],
      // navigation: {
      //   nextEl: `.${id}-next-arrow`,
      //   prevEl: `.${id}-prev-arrow`,
      //   disabledClass: 'swiper-button-disabled',
      // },
      breakpoints: {
        0: {
          slidesPerView: 2.3,
        },
        639: {
          slidesPerView: 2,
        },
        865: {
          slidesPerView: 2,
        },
        1000: {
          slidesPerView: 3,
        },
      },
    });
    return () => {
      if (swiperCarService) {
        swiperCarService.destroy();
      }
    };
  }, [i18n.language]);

  return (
    <>
      <section
        className="bg-no-repeat bg-[center_center] bg-cover relative z-0 h-[300px] lg:h-[500px] mt-4 "
        // style={{ backgroundImage: 'url(/images/car-service.png)' }}car-services-bg
      >
        <Image
          layout="fill"
          loading="lazy"
          src={
            isMobileOnly
              ? '/images/car-service-mobile.jpg'
              : '/images/car-service.jpg'
          }
          alt={'car-service-bg'}
          objectFit="cover"
          objectPosition={'center'}
        />
      </section>
      <section className="!bg-[#ffffff] z-10 mb-16">
        <div className="gogo-container">
          {/* <div className="flex flex-wrap mx-[0px] xl:mx-[-12.5px] lg:mx-[-12.5px] lg:mx-[-12.5px] !mb-[2rem] mt-[-14rem] lg:mt-[-9rem] xl:!mb-[2rem] lg:!mb-[2rem] lg:!mb-[2rem]"> */}
          <div className=" mt-[-130px] lg:mt-[-130px]">
            <h1
              className="relative mb-5 text-white font-semibold text-[20px] lg:text-[32px]"
              tabIndex={650}
            >
              {LabelConstants?.GOGO_MOTOR_CAR_SERVICES}
            </h1>

            <div className="relative">
              <div className="swiper !p-1">
                <div
                  className={`car-service-swiper`}
                  dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                >
                  <div className="swiper-wrapper">
                    <div
                      className={`swiper-slide relative cursor-grab`}
                      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    >
                      <div className="flex flex-col bg-white rounded-[8px] lg:rounded-none h-[201px] lg:h-[320px] !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                        <div className="flex-[1_1_auto] px-[10px] lg:px-[28px] pt-2 lg:pt-[24px] mb-auto">
                          <CarHealth />
                          <div className="mt-[8px] lg:mt-[22px] text-[15px] lg:text-[22px] leading-[18.9px] lg:leading-[27.72px] font-medium text-[#272828]">
                            {LabelConstants?.CAR_INSPECTION}
                          </div>
                          <p className="mt-[14px] text-[#848080] font-normal lg:leading-[22.68px] lg:text-[18px] hidden lg:block">
                            {LabelConstants?.CAR_INSPECTION_CONTENT}
                          </p>
                        </div>
                        <hr className="bg-gray-95 border-none h-[1px] mt-[25px] lg:mt-[44px]" />
                        <div className="py-[13px] px-[10px] lg:py-[24px] lg:px-[24px]">
                          <p className="font-medium text-[13px] lg:text-[20px] leading-[16.38px] lg:leading-[25.2px] text-[#757575]">
                            {LabelConstants?.COMING_SOON}....
                            {/* {t(LabelConstants.CAR_INSPECTION_LINK_TEXT)} */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`swiper-slide relative cursor-grab`}
                      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    >
                      <div className="flex flex-col bg-white rounded-[8px] lg:rounded-none h-[201px] lg:h-[320px] !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                        <div className="flex-[1_1_auto] px-[10px] lg:px-[28px] pt-2 lg:pt-[24px]  mb-auto">
                          {/* <CarInsurance /> */}
                          <div className="ml-1 mb-6">
                            <Image
                              src={`/images/gogo-brand/3.png`}
                              alt="gogo-brand-logo"
                              loading="lazy"
                              width={56}
                              height={56}
                            />
                          </div>
                          <div className="mt-[8px] lg:mt-[22px] text-[15px] lg:text-[22px] leading-[18.9px] lg:leading-[27.72px] font-medium text-[#272828]">
                            {LabelConstants?.EXTENDED_WARRANTY}
                          </div>
                          <p className="mt-[14px] text-[#848080] font-normal lg:leading-[22.68px] lg:text-[18px] hidden lg:block">
                            {LabelConstants?.EXTENDED_WARRANTY_CONTENT}
                          </p>
                        </div>
                        <hr className="bg-gray-95 border-none h-[1px] mt-[25px] lg:mt-[40px]" />
                        <div className="py-[13px] px-[10px] lg:py-[24px] lg:px-[24px]">
                          <p className="font-medium text-[13px] lg:text-[20px] leading-[16.38px] lg:leading-[25.2px] text-[#757575]">
                            {LabelConstants?.COMING_SOON}....
                            {/* {t(LabelConstants.ROADSIDE_ASSISTANCE_LINK_TEXT)} */}
                          </p>
                        </div>
                        {/* <div className="py-[13px] px-[10px] lg:py-[24px] lg:px-[24px]">
                    <div
                      className="cursor-pointer font-medium text-[13px] lg:text-[20px] text-[#E02E01]"
                      onClick={() => {
                        router.push('/vas/warranty');
                        cleverTap.event.push(
                          `hp_extended_warranty_cta_clicked`
                        );
                      }}
                    >
                      {t(LabelConstants.EXTENDED_WARRANTY_LINK_TEXT)}
                    </div>
                  </div> */}
                      </div>
                    </div>
                    <div
                      className={`swiper-slide relative cursor-grab`}
                      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    >
                      <div className="flex flex-col bg-white rounded-[8px] lg:rounded-none h-[201px] lg:h-[320px] !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                        <div className="flex-[1_1_auto] px-[10px] lg:px-[28px] pt-2 lg:pt-[24px]  mb-auto">
                          <CarDiscBrakeRepair />
                          <div className="mt-[8px] lg:mt-[22px] text-[15px] lg:text-[22px] leading-[18.9px] lg:leading-[27.72px] font-medium text-[#272828]">
                            {LabelConstants?.ROADSIDE_ASSISTANCE}
                          </div>
                          <p className="mt-[14px] text-[#848080] font-normal lg:leading-[22.68px] lg:text-[18px] hidden lg:block">
                            {LabelConstants?.ROADSIDE_ASSISTANCE_CONTENT}
                          </p>
                        </div>
                        <hr className="bg-gray-95 border-none h-[1px] mt-[25px] lg:mt-[40px]" />
                        <div className="py-[13px] px-[10px] lg:py-[24px] lg:px-[24px]">
                          <p className="font-medium text-[13px] lg:text-[20px] leading-[16.38px] lg:leading-[25.2px] text-[#757575]">
                            {LabelConstants?.COMING_SOON}....
                            {/* {t(LabelConstants.ROADSIDE_ASSISTANCE_LINK_TEXT)} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <ViewAll
            label={t(LabelConstants.EXPLORE_ALL_VALUE_ADDED_SERVICES)}
            link="/"
          /> */}
        </div>
      </section>
    </>
  );
};

export default CarServices;
