import React, { useEffect, useState } from 'react';
import { ListingService } from '../../helpers/services';
import { CommonUtils, FilterUtils } from '../../helpers/utilities';
import { useRouter } from 'next/router';
import { MultipleSortByFilter, SortDirection } from '../../types/enums';
import { SearchAllResultItem, SearchRequestPayload } from '../../types/models';
import { Tabs, TabsList, TabsTrigger } from '../common/TabsNew';
import SwiperCarousel from './SwiperCarousel';
import { useTranslation } from 'next-i18next';
import { useAppSelector } from '../../lib/hooks';
import { useTranslationContext } from '../../provider/TranslationProvider';

interface PropType {
  label?: string;
}

const DiscountedNewCarsBudget: React.FC<PropType> = ({ label = '' }) => {
  const [discountedNewCars, setDiscountedNewCars] = useState<
    Array<SearchAllResultItem>
  >([]);
  const cleverTap = useAppSelector(({ global }) => global.clevertap);

  const [currentTab, setCurrentTab] = useState<string>('');
  const router = useRouter();
  const { i18n } = useTranslation();
  const { LabelConstants } = useTranslationContext();

  useEffect(() => {
    const init = async () => {
      let searchPayload: SearchRequestPayload = {
        LanguageID: CommonUtils.getLanguageId(router.locale!),
        IndexName: '',
        Size: 10,
        Page: 1,
        Filter: {
          wildcard: '',
          term: ['IsNew=True', 'IsOutlet=False'],
          range: [],
          csv: [],
        },
        OrderBy: [
          `${MultipleSortByFilter.AskingPrice}  ${SortDirection.Asc}`,
          `${MultipleSortByFilter.VehicleMake}  ${SortDirection.Asc}`,
          `${MultipleSortByFilter.VehicleModel}  ${SortDirection.Asc}`,
          `${MultipleSortByFilter.Spec}  ${SortDirection.Asc}`,
        ],
        SelectField: FilterUtils.getNewSelectField(),
        SearchField: ['VehicleMake', 'VehicleModel'],
      };
      if (currentTab) {
        searchPayload.Filter.range = [`AskingPrice=${currentTab}`];
      }

      const vehicleList = await ListingService.searchAllVehicle(searchPayload);
      setDiscountedNewCars(vehicleList.DataList);

      // setPreOwnedCars(vehicles.DataList);
    };
    init();
  }, [router.locale, currentTab]);

  return (
    <div className="max-w-full theme-v1">
      <h1 className="text-[16px] sm:text-[32px] mb-[20px] lg:mb-[36px] font-semibold">
        {label}
      </h1>
      <Tabs
        onValueChange={(value) => setCurrentTab(value)}
        defaultValue={currentTab}
        className="w-full "
        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
      >
        <TabsList className="box-border w-full gogo-motor-scroll-move">
          <TabsTrigger
            value=""
            className="!h-[28px] lg:!h-[38px] text-[12px] lg:text-[15px] px-3 lg:px-6 text-gray-100 data-[state=active]:text-[#3A3A3C] data-[state=active]:font-medium data-[state=active]:border-[#3A3A3C]"
            onClick={() => {
              cleverTap.event.push(
                `hp_discounted_new_car_price_range_all_selected`
              );
            }}
            id="tab-1"
            aria-selected={currentTab == ''}
            aria-controls="tabpanel-1"
            role="tab"
            aria-hidden="true"
            tabIndex={700}
          >
            {LabelConstants?.ALL_OFFERS}
          </TabsTrigger>

          <TabsTrigger
            value={'0,75000'}
            className="!h-[28px] lg:!h-[38px] text-[12px] lg:text-[15px] px-3 lg:px-6 text-gray-100 data-[state=active]:text-[#3A3A3C] data-[state=active]:font-medium data-[state=active]:border-[#3A3A3C]"
            onClick={() => {
              cleverTap.event.push(
                `hp_discounted_new_car_price_range_0_75000_selected`
              );
            }}
            id="tab-2"
            aria-selected={currentTab == '0,75000'}
            aria-controls="tabpanel-2"
            role="tab"
            tabIndex={701}
          >
            {LabelConstants?.UNDER} {LabelConstants?.SAR} 75,000
          </TabsTrigger>
          <TabsTrigger
            value={'75000,130000'}
            className="!h-[28px] lg:!h-[38px] text-[12px] lg:text-[15px] px-3 lg:px-6 text-gray-100 data-[state=active]:text-[#3A3A3C] data-[state=active]:font-medium data-[state=active]:border-[#3A3A3C]"
            onClick={() => {
              cleverTap.event.push(
                `hp_discounted_new_car_price_range_75000_130000_selected`
              );
            }}
            tabIndex={702}
          >
            {LabelConstants?.SAR} 75,000 - 1,30,000
          </TabsTrigger>
          <TabsTrigger
            value={'130000,200000'}
            className="!h-[28px] lg:!h-[38px] text-[12px] lg:text-[15px] px-3 lg:px-6 text-gray-100 data-[state=active]:text-[#3A3A3C] data-[state=active]:font-medium data-[state=active]:border-[#3A3A3C]"
            onClick={() => {
              cleverTap.event.push(
                `hp_discounted_new_car_price_range_130000_200000_selected`
              );
            }}
            tabIndex={703}
          >
            {LabelConstants?.SAR} 1,30,000 - 2,00,000
          </TabsTrigger>
          <TabsTrigger
            value={'200000,250000'}
            className="!h-[28px] lg:!h-[38px] text-[12px] lg:text-[15px] px-3 lg:px-6 text-gray-100 data-[state=active]:text-[#3A3A3C] data-[state=active]:font-medium data-[state=active]:border-[#3A3A3C]"
            onClick={() => {
              cleverTap.event.push(
                `hp_discounted_new_car_price_range_200000_250000_selected`
              );
            }}
            tabIndex={704}
          >
            {LabelConstants?.SAR} 2,00,000 - 2,50,000
          </TabsTrigger>
          <TabsTrigger
            tabIndex={705}
            value={'250000,3000000'}
            className="!h-[28px] lg:!h-[38px] text-[12px] lg:text-[15px] px-3 lg:px-6 text-gray-100 data-[state=active]:text-[#3A3A3C] data-[state=active]:font-medium data-[state=active]:border-[#3A3A3C]"
            onClick={() => {
              cleverTap.event.push(
                `hp_discounted_new_car_price_range_250000+_selected`
              );
            }}
          >
            {LabelConstants?.SAR} 2,50,000+
          </TabsTrigger>

          {/* {bodyTypes.map((item) => (
         
          ))} */}
        </TabsList>
      </Tabs>

      <div className="mt-5 lg:mt-9">
        {discountedNewCars.length > 0 && (
          <SwiperCarousel
            id="discount-new-car-id"
            silderData={discountedNewCars}
            className="min-h-[263px] lg:min-h-[345px]"
            isEmiStartingFrom={true}
            isFeatures={true}
            isBudget={true}
            cleverName="hp_discounted_new_car_clicked"
            tabIndex={710}
          />
        )}
        {discountedNewCars.length === 0 && (
          <p className="text-center text-lg">
            {LabelConstants?.NO_CARS_PRICE_TEXT}
          </p>
        )}
      </div>
    </div>
  );
};

export default DiscountedNewCarsBudget;
