const GogoLoader = ({}) => {
  return (
    <div className="globalloader">
      <picture>
        <img src="/images/loader.gif" height={150} width={150} alt={'loader'} />
      </picture>
    </div>
  );
};

export default GogoLoader;
