import Link from 'next/link';
import { useAppSelector } from '../../lib/hooks';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useTranslationContext } from '../../provider/TranslationProvider';

const CheckValuation = () => {
  const cleverTap = useAppSelector(({ global }) => global.clevertap);
  const router = useRouter();
  const { LabelConstants } = useTranslationContext();
  return (
    <>
      <div className="mb-16 bg-[#FAECF4] w-full">
        <div className="grid grid-cols-12">
          <div className="col-span-4 flex items-center">
            <Image
              alt={'car03'}
              loading="lazy"
              src="/images/hatchback-mockup.png"
              width={752}
              height={400}
              className="image-transform"
            />
          </div>
          <div className="col-span-7 py-4 md:py-12">
            <div className="text-[#4c0055d6] text-[14px] lg:text-[52px] font-medium">
              {LabelConstants?.LOOKING_TO_SELL_YOUR_CAR}
            </div>
            <div className="font-normal text-[#272828c9]  text-[12px] lg:text-[22px]">
              {LabelConstants?.LOOKING_TO_SELL_YOUR_CAR_CONTENT}
            </div>
            {/* <Link href={'/sell-my-car?mode=sell'} className="text-xl"> */}
            <button
              tabIndex={601}
              type="button"
              className="mt-5 lg:mt-10 rounded-lg lg:rounded-none h-10 lg:h-11 w-28 lg:w-40 bg-[#68266E] font-normal text-white text-xs lg:text-lg outline-none focus:outline-none"
              onClick={() => {
                router.push('sell-my-car');
                cleverTap.event.push(`hp_list_car_cta_clicked`);
              }}
            >
              {/* {t(LabelConstants.CHECK_VALUATION)} */}
              {LabelConstants?.LIST_YOUR_CAR}
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckValuation;
