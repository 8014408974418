export const CarDiscBrakeRepair = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M14.0814 32.9595H14.7355C15.0292 40.9655 20.8049 47.8814 28.6888 49.5409C28.7332 49.5502 28.7777 49.5547 28.8212 49.5547C29.1176 49.5547 29.3835 49.3479 29.4468 49.0465C29.5198 48.7006 29.2984 48.3614 28.9524 48.2884C21.6577 46.7527 16.3086 40.3636 16.0155 32.9595H21.7825C22.1147 38.4852 26.7134 42.8795 32.3214 42.8795C32.4043 42.8795 32.4872 42.8791 32.57 42.8756C32.9233 42.8603 33.1969 42.5617 33.1816 42.2084C33.1662 41.8551 32.868 41.5803 32.5144 41.5969C32.45 41.5998 32.3857 41.5995 32.3214 41.5995C27.4196 41.5995 23.396 37.779 23.066 32.9595H23.6814C24.0347 32.9595 24.3214 32.6727 24.3214 32.3195C24.3214 27.9083 27.9102 24.3195 32.3214 24.3195C32.6747 24.3195 32.9614 24.0327 32.9614 23.6795V23.0615C36.2171 23.283 39.1067 25.1854 40.6052 28.1387C40.7182 28.3611 40.9432 28.4891 41.1764 28.4891C41.2737 28.4891 41.3726 28.467 41.4654 28.4196C41.7806 28.2596 41.9064 27.8747 41.7464 27.5595C40.0286 24.1745 36.7009 22.0139 32.9611 21.7902V16.0126C39.2129 16.2571 44.7966 20.0647 47.3051 25.8539C47.4097 26.0955 47.6452 26.2398 47.8926 26.2398C47.9774 26.2398 48.0638 26.2228 48.1467 26.1867C48.4712 26.0462 48.62 25.6695 48.4795 25.3451C45.7684 19.0868 39.724 14.9761 32.9614 14.7313V14.0795C32.9614 13.7262 32.6747 13.4395 32.3214 13.4395C21.9108 13.4395 13.4414 21.9089 13.4414 32.3195C13.4414 32.6727 13.7281 32.9595 14.0814 32.9595ZM31.6814 14.731V23.0612C27.0734 23.3767 23.3787 27.0715 23.0632 31.6795H14.7329C15.0635 22.483 22.4849 15.0615 31.6814 14.731Z"
        fill="#4C0055"
      />
      <path
        d="M28.8008 32.3195C28.8008 34.2603 30.38 35.8395 32.3208 35.8395C34.2616 35.8395 35.8408 34.2603 35.8408 32.3195C35.8408 30.3787 34.2616 28.7995 32.3208 28.7995C30.38 28.7995 28.8008 30.3787 28.8008 32.3195ZM32.3208 30.0795C33.556 30.0795 34.5608 31.0843 34.5608 32.3195C34.5608 33.5547 33.556 34.5595 32.3208 34.5595C31.0856 34.5595 30.0808 33.5547 30.0808 32.3195C30.0808 31.0843 31.0856 30.0795 32.3208 30.0795ZM42.4283 29.3071C41.2757 30.4597 40.6408 31.9922 40.6408 33.6223V35.7941C40.6408 36.4779 40.3745 37.1208 39.891 37.6043L31.7083 45.7871C31.0715 46.4239 30.7208 47.2706 30.7208 48.1707V48.4683C30.7208 49.3685 31.0715 50.2152 31.7083 50.852C32.3451 51.4888 33.1915 51.8395 34.0923 51.8395H34.3896C35.2901 51.8395 36.1368 51.4888 36.7736 50.852L44.9563 42.6693C45.4398 42.1858 46.0827 41.9195 46.7665 41.9195H48.9384C50.5685 41.9195 52.1009 41.285 53.2536 40.132C54.4062 38.9794 55.0411 37.4469 55.0411 35.8168V33.412C55.0411 32.8098 54.5509 32.3195 53.9486 32.3195C53.6565 32.3195 53.3819 32.4331 53.1761 32.6395L49.9208 35.8942L46.6657 32.6395L49.9211 29.3845C50.1272 29.1778 50.2408 28.9039 50.2408 28.612C50.2408 28.0098 49.7505 27.5195 49.1483 27.5195H46.7435C45.1134 27.5195 43.5809 28.1541 42.4283 29.3071ZM45.7608 31.7346C45.2616 32.2338 45.2616 33.0456 45.7608 33.5448L49.0155 36.7995C49.2571 37.0411 49.5787 37.1746 49.9208 37.1746C50.2629 37.1746 50.5841 37.0411 50.8257 36.7995L53.7608 33.8648V35.8168C53.7608 37.1051 53.2593 38.3163 52.3483 39.2271C51.4373 40.1378 50.2264 40.6395 48.9381 40.6395H46.7662C45.7403 40.6395 44.7761 41.0389 44.051 41.7643L35.8683 49.9471C35.4734 50.3419 34.9483 50.5595 34.3893 50.5595H34.092C33.5333 50.5595 33.0081 50.3419 32.6129 49.9471C32.2177 49.5522 32.0008 49.0267 32.0008 48.4683V48.1707C32.0008 47.6123 32.2184 47.0869 32.6133 46.692L40.796 38.5093C41.5214 37.7839 41.9208 36.8197 41.9208 35.7941V33.6223C41.9208 32.3339 42.4222 31.1227 43.3333 30.212C44.2443 29.3013 45.4552 28.7995 46.7435 28.7995H48.6958L45.7608 31.7346Z"
        fill="#4C0055"
      />
      <path
        d="M38.4011 33.5991C39.108 33.5991 39.6811 33.026 39.6811 32.3191C39.6811 31.6121 39.108 31.0391 38.4011 31.0391C37.6942 31.0391 37.1211 31.6121 37.1211 32.3191C37.1211 33.026 37.6942 33.5991 38.4011 33.5991Z"
        fill="#4C0055"
      />
      <path
        d="M32.3191 27.5209C33.026 27.5209 33.5991 26.9479 33.5991 26.2409C33.5991 25.534 33.026 24.9609 32.3191 24.9609C31.6121 24.9609 31.0391 25.534 31.0391 26.2409C31.0391 26.9479 31.6121 27.5209 32.3191 27.5209Z"
        fill="#4C0055"
      />
      <path
        d="M26.2409 33.5991C26.9479 33.5991 27.5209 33.026 27.5209 32.3191C27.5209 31.6121 26.9479 31.0391 26.2409 31.0391C25.534 31.0391 24.9609 31.6121 24.9609 32.3191C24.9609 33.026 25.534 33.5991 26.2409 33.5991Z"
        fill="#4C0055"
      />
      <path
        d="M32.3191 39.6811C33.026 39.6811 33.5991 39.108 33.5991 38.4011C33.5991 37.6942 33.026 37.1211 32.3191 37.1211C31.6121 37.1211 31.0391 37.6942 31.0391 38.4011C31.0391 39.108 31.6121 39.6811 32.3191 39.6811Z"
        fill="#4C0055"
      />
    </svg>
  );
};
