import { Navigation } from 'swiper/modules';
// import { Swiper, SwiperSlide } from 'swiper/react';
import { BodyType } from '../../types/models';
import { ChevronLeftIcon } from '../icons';
import Link from 'next/link';
import { useAppSelector } from '../../lib/hooks';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import { Swiper } from 'swiper';
import Image from 'next/image';

type PropType = {
  bodyTypes: Array<BodyType>;
  direction: string;
};
Swiper.use([Navigation]);

const ExploreByBodyType: React.FC<PropType> = (props) => {
  const { i18n } = useTranslation();
  const { bodyTypes, direction } = props;
  const router = useRouter();
  const cleverTap = useAppSelector(({ global }) => global.clevertap);

  useEffect(() => {
    var swiperBanner = new Swiper(`.explore-by-body-type-swiper`, {
      spaceBetween: 20,
      slidesPerView: 4,
      slidesPerGroup: 1,
      // modules: [Navigation],
      navigation: {
        nextEl: `.explore-by-body-type-next-arrow`,
        prevEl: `.explore-by-body-type-prev-arrow`,
        disabledClass: 'swiper-button-disabled',
      },
      breakpoints: {
        0: {
          slidesPerView: 3,
        },
        639: {
          slidesPerView: 4,
        },
        865: {
          slidesPerView: 5,
        },
        1000: {
          slidesPerView: 7,
        },
      },
    });
    return () => {
      if (swiperBanner) {
        swiperBanner?.destroy();
      }
    };
  }, [i18n.language, bodyTypes]);

  const isRTL = useMemo(() => {
    return i18n.language === 'ar';
  }, [i18n.language]);
  return (
    <div className="relative">
      <div className="swiper !p-1">
        <div
          className={`explore-by-body-type-swiper`}
          dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
        >
          <div className="swiper-wrapper">
            {bodyTypes.map((item, index) => (
              <div
                className="swiper-slide relative cursor-grab"
                key={index}
                dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
              >
                <div
                  className="cursor-pointer"
                  tabIndex={910 + index}
                  onClick={() => {
                    router.push(
                      `${encodeURI(
                        `/all-listings?bodytype=${item.BodyTypeKey}`
                      )}`
                    );
                    cleverTap.event.push(
                      `hp_body_type_${item?.BodyType}_selected`
                    );
                  }}
                >
                  <div className="bg-white h-[80px] md:h-[144px] grid rounded-[8px] md:rounded-none">
                    <div
                      className={`relative flex justify-center ${
                        i18n.language === 'ar' ? 'items-start' : 'items-center'
                      }`}
                    >
                      <div className="h-[48px] w-[48px] lg:w-[72px] lg:h-[72px]">
                        <Image
                          alt={item.BodyType}
                          loading="lazy"
                          src={item.ImageUrlPath}
                          width={72}
                          height={72}
                        />
                      </div>
                    </div>
                    <div className="text-[#050B20] font-medium text-[12px] md:text-[20.01px] text-center mb-auto">
                      {item.BodyType}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="opacity-0 sm:opacity-100">
        <button
          className={`explore-by-body-type-prev-arrow absolute z-10 top-[45%] ${
            isRTL ? 'right-[0px] md:-right-[12px]' : '-left-[12px]'
          }`}
          id="previd"
          aria-label="swiperpprev"
          tabIndex={950}
        >
          <ChevronLeftIcon
            className={`w-8 h-8 ${
              isRTL ? 'rotate-180' : ''
            } bg-white hover:bg-white p-1 pr-2 rounded-full shadow-[0px_-2px_6px_0px_#00000024]`}
          />
        </button>
        <button
          className={`explore-by-body-type-next-arrow absolute z-10 top-[45%]  ${
            isRTL ? '-left-[12px]' : 'right-[0px] md:-right-[12px]'
          }`}
          id="nextid"
          aria-label="swiperpnext"
          tabIndex={960}
        >
          <ChevronLeftIcon
            className={`w-8 h-8 ${
              isRTL ? '' : 'rotate-180'
            } bg-white p-1 pr-2 rounded-full shadow-[0px_2px_6px_0px_#00000024]`}
          />
        </button>
      </div>
    </div>
  );
};

export default ExploreByBodyType;
