import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
  NextPage,
} from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useCallback, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { ListingService, VehicleService } from '../helpers/services';
import { CommonUtils, FilterUtils } from '../helpers/utilities';
import { AppTheme } from '../types/constants';
import { Locales, SortByFilter, SortDirection } from '../types/enums';
import { useTranslationContext } from '../provider/TranslationProvider';
import {
  BodyType,
  Brand,
  City,
  SearchRequestPayload,
  SearchResultItem,
  TrendingCars,
} from '../types/models';
import { useRouter } from 'next/router';
import ConfigurationService from '../helpers/services/configuration.service';
import ImageCarousel from '../components/home/ImageCarousel';
import SearchWidget from '../components/home/SearchWidget';
import SwiperCarousel from '../components/home/SwiperCarousel';
import ViewAll from '../components/home/ViewAll';
import TopSearchedUsedCars from '../components/home/TopSearchedUsedCars';
import CheckValuation from '../components/home/CheckValuation';
import CarServices from '../components/home/CarServices';
import DiscountedNewCarsBudget from '../components/home/DiscountedNewCarsBudget';
import BrowseByLocation from '../components/home/BrowseByLocation';
import ExploreByBodyType from '../components/home/ExploreByBodyType';
import GetNotified from '../components/home/GetNotified';
import { useCityContext } from '../provider/CityProvider';
import SelectCity from '../components/home/SelectCity';
import { ChevronLeftIcon } from '../components/icons';
import EmiCalculator from '../components/home/EmiCalculator';

type HomePageProps = {
  topBrands: Array<Brand>;
  bodyTypes: Array<BodyType>;
};

const Home: NextPage<HomePageProps> = ({
  topBrands,
  bodyTypes,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { i18n } = useTranslation();
  const router = useRouter();
  const [preOwnedCarsList, setPreOwnedCarsList] = useState<
    Array<SearchResultItem>
  >([]);
  const [trendingCars, setTrendingCars] = useState<Array<TrendingCars>>([]);
  const [dealLink, setDealLink] = useState<any>();
  const [isLoadingBannerList, setIsLoadingBannerList] = useState(false);
  const [isLoadingExclusiveList, setIsLoadingExclusiveList] = useState(false);
  const [isLoadingPreOwnedList, setIsLoadingPreOwnedList] = useState(false);
  const [isLoadingTrendingList, setIsLoadingTrendingList] = useState(false);
  const [isLoadingUpcomingCars, setIsLoadingUpcomingCars] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);
  const [show, setShow] = useState(false);
  const [sliders, setSliders] = useState<Array<any>>([]);
  const [carDeals, setCarDeals] = useState<Array<any>>([]);
  const [upcomingCars, setUpcomingCars] = useState<Array<any>>([]);
  const { cities, selectCity, setSelectCity } = useCityContext();
  const { LabelConstants } = useTranslationContext();
  const [openModal, setOpenModal] = useState(false);

  const fetchBannersList = useCallback(async () => {
    setIsLoadingBannerList(true);
    try {
      let list: any = await ConfigurationService.getBannersData(
        router.locale,
        'Homepage'
      );
      setSliders(list);
      setIsLoadingBannerList(false);
    } catch (error) {
      setIsLoadingBannerList(false);
      // console.error(`Error in ConfigurationService:fetchBannersList`, error);
    }
  }, [router.locale]);

  const fetchExclusiveCarList = useCallback(async () => {
    setIsLoadingExclusiveList(true);
    try {
      let list: any = await VehicleService.fetchExclusiveCarDeals(
        router.locale,
        '1',
        '15'
      );
      setCarDeals(list);
      setIsLoadingExclusiveList(false);
    } catch (error) {
      setIsLoadingExclusiveList(false);
      // console.error(
      //   `Error in ConfigurationService:fetchExclusiveCarList`,
      //   error
      // );
    }
  }, [router.locale]);

  const fetchPreOwnedCarsList = useCallback(async () => {
    if (selectCity?.CityId) {
      setIsLoadingPreOwnedList(true);
      let searchPayload: SearchRequestPayload = {
        LanguageID: CommonUtils.getLanguageId(router.locale!),
        IndexName: '',
        Size: 15,
        Page: 1,
        Filter: {
          wildcard: '',
          term: [
            `CityId=${selectCity?.CityId}`,
            'IsNew=False',
            'IsOutlet=False',
          ],
          range: [],
          csv: [],
        },
        OrderBy: [`${SortByFilter.ListedDate}  ${SortDirection.Desc}`],
        SelectField: FilterUtils.getSelectField(),
        SearchField: ['VehicleMake', 'VehicleModel'],
      };
      try {
        let list = await ListingService.searchVehicle(searchPayload);
        let preOwnedCarsList = list && list.DataList ? list.DataList : [];
        setPreOwnedCarsList(preOwnedCarsList);
        setIsLoadingPreOwnedList(false);
      } catch (error) {
        setIsLoadingPreOwnedList(false);
        // console.error(`Error in VehicleService:fetchListingImages`, error);
      }
    }
  }, [router.locale, selectCity?.CityId]);

  const fetchTrendingCarsList = useCallback(async () => {
    setIsLoadingTrendingList(true);
    try {
      const trendingCarsList: any = await VehicleService.fetchTrendingCars(
        router.locale
      );
      const trendingCars = (trendingCarsList && trendingCarsList.data) || [];
      setTrendingCars(trendingCars);
      setIsLoadingTrendingList(false);
    } catch (error) {
      setIsLoadingTrendingList(false);
      // console.error(`Error in VehicleService:fetchListingImages`, error);
    }
  }, [router.locale]);

  const fetchDealsLink = useCallback(async () => {
    try {
      const dealsLink: any = await ConfigurationService.getDealsLink(
        router.locale
      );
      setDealLink(dealsLink);
    } catch (error) {
      // console.error(`Error in VehicleService:fetchListingImages`, error);
    }
  }, [router.locale]);

  const fetchUpcomingCars = useCallback(async () => {
    setIsLoadingUpcomingCars(true);
    try {
      const cars: any = await VehicleService.getUpcomingCars(router.locale);
      setUpcomingCars(cars);
      setIsLoadingUpcomingCars(false);
    } catch (error) {
      setIsLoadingUpcomingCars(false);
      // console.error(`Error in VehicleService:fetchListingImages`, error);
    }
  }, [router.locale]);

  useEffect(() => {
    const init = async () => {
      fetchBannersList();
      fetchTrendingCarsList();
      fetchExclusiveCarList();
      fetchDealsLink();
      fetchUpcomingCars();
    };
    init();
  }, [
    fetchBannersList,
    fetchExclusiveCarList,
    fetchTrendingCarsList,
    fetchDealsLink,
    fetchUpcomingCars,
  ]);

  useEffect(() => {
    const init = async () => {
      fetchPreOwnedCarsList();
    };
    init();
  }, [fetchPreOwnedCarsList]);

  return (
    <>
      <div className="gogo-home-page">
        {/* Banner Image Section */}
        <section className="relative mb-8 md:mb-16">
          <ImageCarousel
            slides={sliders}
            direction={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            isLoading={isLoadingBannerList}
          />
          <SearchWidget
            topBrands={topBrands.slice(0, 9)}
            direction={i18n.language === 'ar' ? 'rtl' : 'ltr'}
          />
        </section>
        {/* Pre Owned Cars */}
        {!isLoadingPreOwnedList && preOwnedCarsList.length > 0 && (
          <div className="gogo-container mb-8 md:mb-16 theme-v1">
            <SwiperCarousel
              id="top-rated-id"
              label={LabelConstants?.LATEST_PRE_OWNED_CARS}
              cities={cities}
              silderData={preOwnedCarsList}
              isFeatures={true}
              isSpecifications={true}
              isCity={true}
              isAskingPrice={true}
              className="h-min-[300px] md:h-[387px]"
              cleverName="hp_recommended_used_car_clicked"
              isLoading={isLoadingPreOwnedList}
              tabIndex={197}
            />
            <ViewAll
              label={LabelConstants?.VIEW_ALL_RECOMMEDED_CARS}
              link="/cars?carType=used-cars"
              tabIndex={280}
            />
          </div>
        )}
        {/* In demand new cars */}
        <section className="gogo-container mb-8 md:mb-16 theme-v1">
          <SwiperCarousel
            id="in-demand-new-cars-id"
            label={LabelConstants?.POPULAR_NEW_CARS}
            silderData={trendingCars}
            isEstimatedPrice={true}
            isCheckOnRoadPrice={true}
            // badgeText={t(LabelConstants.NEW_LAUNCH)}
            // className="h-min-[247px] md:h-[339px]"
            className="h-min-[200px] md:h-[270px]"
            isLink={true}
            cleverName="hp_recommended_used_car_clicked"
            isLoading={isLoadingTrendingList}
            tabIndex={300}
          />
          <ViewAll
            label={LabelConstants?.VIEW_ALL_POPULAR_NEW_CARS}
            link="/newcars/new"
            tabIndex={380}
          />
        </section>
        {/* Exclusive Car Deals */}
        <section className="mb-8 md:mb-16 theme-v1 py-9 bg-[linear-gradient(256.85deg,#FFE6DD_0.51%,rgba(231,230,255,0.71)_100%)]">
          <div className="gogo-container">
            <SwiperCarousel
              id="exclusive-car-deals-id"
              label={LabelConstants?.EXCLUSIVE_CAR_DEALS}
              silderData={carDeals}
              className="h-min-[300px] md:h-[380px]"
              description={
                LabelConstants?.DRIVE_AWAY_WITH_ZERO_DOWN_PAYMENT_TEXT
              }
              isSideAssistance={true}
              isEmiStartingFrom={true}
              isLink={false}
              cleverName="hp_recommended_used_car_clicked"
              isLoading={isLoadingExclusiveList}
              tabIndex={400}
            />
            <ViewAll
              tabIndex={480}
              label={LabelConstants?.VIEW_ALL_CARS_ON_DEAL}
              link={'/campaigns'}
            />
          </div>
        </section>
        {/* Top searched used cars */}
        {!isLoadingPreOwnedList && preOwnedCarsList.length > 0 && (
          <section className="gogo-container mb-8 md:mb-16 theme-v1">
            <TopSearchedUsedCars
              label={LabelConstants?.PRE_OWNED_CARS_BY_CATEGORY}
              cities={cities}
              bodyTypes={bodyTypes}
              selectCityId={selectCity?.CityId}
            />
            <ViewAll
              tabIndex={590}
              label={LabelConstants?.VIEW_ALL_CARS}
              link="/cars?carType=used-cars"
            />
          </section>
        )}
        {/* Looking to sell your car */}
        <CheckValuation />
        {/* On the horizon: Upcoming launches */}
        {/* <section className="gogo-container mb-8 md:mb-16 theme-v1">
          <SwiperCarousel
            id="upcoming-launches-id"
            label={LabelConstants.ON_THE_HORIZON_UPCOMING_LAUNCHER}
            // cities={cities}
            silderData={upcomingCars}
            isEstimatedPrice={true}
            // badgeText={t(LabelConstants.EXPECTED_LAUNCH)}
            className="min-h-[247px] md:min-h-[330px]"
            isNotified={true}
            isLink={false}
            isLoading={isLoadingUpcomingCars}
            setVehicle={(data: any) => {
              setVehicleData(data);
              setShow(true);
            }}
          />
          <ViewAll label={LabelConstants.VIEW_ALL_NEW_CARS} link="/" />
        </section> */}
        {/* gogo motor car services */}
        <CarServices />
        {/* Discounted new cars for your budget */}
        <section className="mb-8 md:mb-16 theme-v1">
          <div className="gogo-container">
            <DiscountedNewCarsBudget
              label={LabelConstants?.DISCOUNTED_NEW_CARS_FOR_YOUR_BUDGET}
            />
            <ViewAll
              label={LabelConstants?.VIEW_ALL_DISCOUNTED_CARS}
              link="/newcars/new"
              tabIndex={790}
            />
          </div>
        </section>
        {/* Explore by location */}
        <div className="gogo-container mobile-container theme-v1 mb-16">
          <h1 className="mb-[20px] md:mb-[36px] text-[20px] lg:text-[34px] leading-[25.2px] lg:leading-[42.84px] text-[#212121] font-semibold">
            {LabelConstants?.EXPLORE_USED_CAR_BY_LOCATION}
          </h1>
          <BrowseByLocation topCities={cities} />
          <div
            tabIndex={890}
            onClick={() => setOpenModal(true)}
            className="cursor-pointer text-[#4C0055] mt-[16px] lg:mt-[28px] text-[13px] lg:text-[20px] leading-[16.38px] lg:leading-[25.2px] font-normal flex items-center gap-2 cursor-pointer"
          >
            {LabelConstants?.VIEW_ALL_CITIES}
            <ChevronLeftIcon
              className={`w-6 h-6 mt-1 ${
                i18n.language === 'ar' ? '' : 'rotate-180'
              }`}
            />
          </div>
        </div>
        {/* Explore by body type */}
        <section className="theme-v1 mb-16 bg-[#F5F5F5] py-12">
          <div className="gogo-container mobile-container ">
            <h1 className="mb-[20px] md:mb-[36px] text-[20px] lg:text-[34px] leading-[25.2px] lg:leading-[42.84px] text-[#212121] font-semibold">
              {LabelConstants?.EXPLORE_BY_BODY_TYPE}
            </h1>
            <ExploreByBodyType
              bodyTypes={bodyTypes}
              direction={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            />{' '}
          </div>
        </section>
        {/* Compare to buy the right car */}
        {/* <section className="mb-8 md:mb-16 theme-v1">
          <div className="container">
            <CompareToBuyRightCar
              label={t(LabelConstants.COMPARE_TO_BUY_THE_RIGHT_CAR)}
              direction={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            />
            <ViewAll label={t(LabelConstants.VIEW_ALL_COMPARES)} link="/" />
          </div>
        </section> */}
        {/* Know your buying power */}
        <EmiCalculator direction={i18n.language === 'ar' ? 'rtl' : 'ltr'} />
        {/* Car kingdom news */}
        {/* <section className="container mobile-container mb-8 md:mb-16 theme-v1">
          <h1 className="text-[16px] sm:text-[32px] mb-4 font-semibold">
            {t(LabelConstants.CAR_KINGDOM_NEWS)}
          </h1>
          <CarKingdomNews />
          <ViewAll label={t(LabelConstants.VIEW_ALL_LATEST_NEWS)} link="/" />
        </section>
        <SpotLightOnBlogs /> */}
      </div>
      {show && (
        <section className="request-quote-modal">
          <GetNotified
            cities={cities}
            show={show}
            setShow={() => {
              setVehicleData(null);
              setShow(false);
            }}
            carData={vehicleData}
          />
        </section>
      )}

      {openModal && (
        <SelectCity
          cities={cities}
          show={openModal}
          onClose={(val: any) => {
            if (val) {
              setSelectCity(val);
              router.push(`/all-listings?city=${val?.CityKey}`);
              localStorage.setItem('city', JSON.stringify(val));
            }
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps<HomePageProps> = async ({
  locale,
}: GetServerSidePropsContext) => {
  const [translations, topBrands, bodyTypes] = await Promise.all([
    serverSideTranslations(locale || Locales.EN, ['common']),
    VehicleService.fetchPopularBrands(locale),
    VehicleService.fetchBodyTypes(locale),
  ]);
  return {
    props: {
      ...(await serverSideTranslations(locale || Locales.EN, ['common'])),
      topBrands,
      bodyTypes: bodyTypes.filter((x) => x.IsSearchable),
      applyTheme: AppTheme.V1,
    },
  };
};
