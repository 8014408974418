import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { Modal, ModalBody } from '../common/Modal';
import {
  FilterParams,
  SearchAllResultItem,
  SearchRequestPayload,
} from '../../types/models';
import {
  CommonUtils,
  FilterUtils,
  formatNumber,
  NewFilterUtils,
} from '../../helpers/utilities';
import {
  MultipleSortByFilter,
  SortByFilter,
  SortDirection,
  VehicleAgeType,
} from '../../types/enums';
import { ListingService } from '../../helpers/services';
import { useRouter } from 'next/router';
import { ChevronLeftIcon } from '../icons';
import { useTranslationContext } from '../../provider/TranslationProvider';
import ConfigurationService from '../../helpers/services/configuration.service';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
Swiper.use([Navigation]);
type PropType = {
  direction: string;
};

const EmiCalculator: React.FC<PropType> = ({ direction }) => {
  const { i18n } = useTranslation();
  const { LabelConstants } = useTranslationContext();
  const router = useRouter();
  const [show, setShow] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isSelectEMI, setIsSelectEMI] = useState(true);
  const [EMIValue, setEMIValue] = useState<number>();
  const [tenure, setTenure] = useState<number>(5);
  const [downPayment, setDownPayment] = useState<number>(5);
  const [bollonPayment, setBollonPayment] = useState<number>(35);
  const [profitRate, setProfitRate] = useState<number>(5);
  const [insurance, setInsurance] = useState<number>(2.5);
  const [adminFees, setAdminFees] = useState<number>(1);
  const [totalEMIWiseLoan, setTotalEMIWiseLoan] = useState<number>(0);
  const [totalVehiclePrice, setTotalVehiclePrice] = useState<number>(0);
  const [downPaymentVal, setDownPaymentVal] = useState<number>();
  const [balloonPaymentVal, setBalloonPaymentVal] = useState<number>();
  const [profitVal, setProfitVal] = useState<number>();
  const [insuredVal, setInsuredVal] = useState<number>();
  const [adminFeesVal, setAdminFeesVal] = useState<number>();
  const [AdditionalProfitRate, setAdditionalProfitRate] = useState<number>(5);
  const [AdditionalInsurance, setAdditionalInsurance] = useState<number>(2.5);
  const [AdditionalAdminFees, setAdditionalAdminFees] = useState<number>(1);
  const [AdditionalProfitRateVal, setAdditionalProfitRateVal] =
    useState<number>();
  const [AdditionalInsuranceVal, setAdditionalInsuranceVal] =
    useState<number>();
  const [AdditionalAdminFeesVal, setAdditionalAdminFeesVal] =
    useState<number>();
  const [budgetCarList, setBudgetCarList] = useState<
    Array<SearchAllResultItem>
  >([]);
  const [EMIInitialValue, setEMIInitialValue] = useState({
    AdminFeesMaximumPercentage: 10,
    AdminFeesMinimumPercentage: 1,
    AdminFeesPercentage: 1,
    BalloonPaymentMaximumPercentage: 100,
    BalloonPaymentMinimumPercentage: 1,
    BalloonPaymentPercentage: 35,
    DefaultEMIMonth: [],
    Depreciated: 15,
    DownPaymentMaximumPercentage: 100,
    DownPaymentMinimumPercentage: 1,
    DownPaymentPercentage: 5,
    InsuranceMaximumPercentage: 10,
    InsuranceMinimumPercentage: 1,
    InsurancePercentage: 2.5,
    MinimumEMIPerMonth: 1500,
    MaximumEMIPerMonth: 100000,
    NumberOfYears: 7,
    ProfitRateMaximumPercentage: 100,
    ProfitRateMinimumPercentage: 1,
    ProfitRatePercentage: 5,
    DefaultYear: 5,
    DownPaymentPercentageStep: 1,
    BalloonPaymentPercentageStep: 1,
    ProfitRatePercentageStep: 0.1,
    InsurancePercentageStep: 0.1,
    AdminFeesPercentageStep: 0.1,
  });
  const [isLoadingEMIConfiguration, setIsLoadingEMIConfiguration] =
    useState(false);

  const fetchEMIConfiguration = useCallback(async () => {
    setIsLoadingEMIConfiguration(true);
    try {
      let list: any = await ConfigurationService.getEMIConfiguration(true);
      setEMIInitialValue(list?.attributes);
      setTenure(list?.attributes?.DefaultYear);
      setDownPayment(list?.attributes?.DownPaymentPercentage);
      setBollonPayment(list?.attributes?.BalloonPaymentPercentage);
      setProfitRate(list?.attributes?.ProfitRatePercentage);
      setInsurance(list?.attributes?.InsurancePercentage);
      setAdminFees(list?.attributes?.AdminFeesPercentage);
      setAdditionalProfitRate(list?.attributes?.ProfitRatePercentage);
      setAdditionalInsurance(list?.attributes?.InsurancePercentage);
      setAdditionalAdminFees(list?.attributes?.AdminFeesPercentage);
      setIsLoadingEMIConfiguration(false);
    } catch (error) {
      setIsLoadingEMIConfiguration(false);
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      fetchEMIConfiguration();
    };
    init();
  }, []);

  const updateAdditionalProfitRate = (val: any) => {
    getAdditionalDetails(val, AdditionalAdminFees, AdditionalInsurance);
    setAdditionalProfitRate(val);
  };

  const updateAdditionalInsurance = (val: any) => {
    getAdditionalDetails(AdditionalProfitRate, AdditionalAdminFees, val);
    setAdditionalInsurance(val);
  };

  const updateAdditionalAdminFees = (val: any) => {
    getAdditionalDetails(AdditionalProfitRate, val, AdditionalInsurance);
    setAdditionalAdminFees(val);
  };

  const getAdditionalDetails = (
    profitrate: any,
    adminFees: any,
    insurance: any
  ) => {
    var emival = Number(EMIValue);
    var profitRatePercentage = Number(profitrate) / 100;
    var downPaymentPercentage = Number(downPayment) / 100;
    var balloonPaymentPercentage = Number(bollonPayment) / 100;
    var durationInYears = Number(tenure) * 12;
    var adminFeePercentage = Number(adminFees) / 100;
    var insurancePercentage = Number(insurance) / 100;
    var monthlyProfitRate = profitRatePercentage / 12;
    var loanPrincipal = (1 + monthlyProfitRate) ** durationInYears;

    var rearrangeP =
      (emival * (loanPrincipal - 1)) / (monthlyProfitRate * loanPrincipal);
    var balloonPaymentVal = 1 - balloonPaymentPercentage;

    var PP = rearrangeP / balloonPaymentVal;

    var downPaymentVal = 1 - downPaymentPercentage;
    var totalLoanAmount = PP / downPaymentVal;

    var insuranceVal = totalLoanAmount * insurancePercentage;

    var adminFeeVal = totalLoanAmount * adminFeePercentage;

    var loanAmount =
      Number(totalLoanAmount) - Number(adminFeeVal + insuranceVal);
    if (isFinite(totalLoanAmount) === false) {
      loanAmount = 0;
      totalLoanAmount = 0;
      PP = 0;
      rearrangeP = 0;
      adminFeeVal = 0;
      insuranceVal = 0;
    }
    var totalProfitValue = loanAmount * profitRatePercentage * tenure;

    setAdditionalInsuranceVal(Number(insuranceVal.toFixed(0)));
    setAdditionalAdminFeesVal(Number(adminFeeVal.toFixed(0)));
    setAdditionalProfitRateVal(Number(totalProfitValue.toFixed(0)));
  };

  useEffect(() => {
    getMonthlyEMI(Number(EMIValue), profitRate, insurance, adminFees);
  }, [tenure, downPayment, bollonPayment]);

  const getMonthlyEMI = (
    newEMIValue: number,
    profitRatePer: number,
    insurancePer: number,
    adminFeesPer: number
  ) => {
    if (Number(newEMIValue) >= Number(EMIInitialValue?.MinimumEMIPerMonth)) {
      var emival = Number(newEMIValue);
      var profitRatePercentage = profitRatePer / 100;
      var downPaymentPercentage = Number(downPayment) / 100;

      var balloonPaymentPercentage = Number(bollonPayment) / 100;
      var durationInYears = Number(tenure) * 12;
      var adminFeePercentage = Number(adminFeesPer) / 100;
      var insurancePercentage = Number(insurancePer) / 100;
      var monthlyProfitRate = profitRatePercentage / 12;
      var loanPrincipal = (1 + monthlyProfitRate) ** durationInYears;
      var rearrangeP =
        (emival * (loanPrincipal - 1)) / (monthlyProfitRate * loanPrincipal);
      var balloonPaymentVal = 1 - balloonPaymentPercentage;
      var PP = rearrangeP / balloonPaymentVal;
      var downPaymentVal = 1 - downPaymentPercentage;
      var totalLoanAmount = PP / downPaymentVal;
      if (isFinite(totalLoanAmount) === false) {
        totalLoanAmount = 0;
        PP = 0;
        rearrangeP = 0;
      }
      var insuranceVal = totalLoanAmount * insurancePercentage;
      var adminFeeVal = totalLoanAmount * adminFeePercentage;
      setDownPaymentVal(Number((totalLoanAmount - PP).toFixed(0)));
      setBalloonPaymentVal(Number((PP - rearrangeP).toFixed(0)));
      setInsuredVal(Number(insuranceVal.toFixed(0)));
      setAdminFeesVal(Number(adminFeeVal.toFixed(0)));
      var loanAmount =
        Number(totalLoanAmount) - Number(adminFeeVal + insuranceVal);
      var totalProfitValue = loanAmount * profitRatePercentage * tenure;

      setProfitVal(Number(totalProfitValue.toFixed(0)));
      setTotalEMIWiseLoan(Number(loanAmount.toFixed(0)));
      var vehiclePrice =
        Number(loanAmount.toFixed(0)) +
        Number((totalLoanAmount - PP).toFixed(0));
      setTotalVehiclePrice(vehiclePrice);
      getSearchAllVehicle(Number(vehiclePrice.toFixed(0)));
    }
  };

  const getSearchAllVehicle = async (val: any) => {
    console.log(val);
    var emivalue = Number(((10 / 100) * val).toFixed(0));
    let searchPayload: SearchRequestPayload = {
      LanguageID: CommonUtils.getLanguageId(router.locale!),
      IndexName: '',
      Size: 6,
      Page: 1,
      Filter: {
        wildcard: '',
        term: ['IsNew=True', 'IsOutlet=False'],
        range: [
          `AskingPrice=${Math.round(
            Number(val) - Number(emivalue)
          )},${Math.round(Number(val) + Number(emivalue))}`,
        ],
        csv: [],
      },
      OrderBy: [
        `${MultipleSortByFilter.AskingPrice}  ${SortDirection.Desc}`,
        `${MultipleSortByFilter.VehicleMake}  ${SortDirection.Asc}`,
        `${MultipleSortByFilter.VehicleModel}  ${SortDirection.Asc}`,
        `${MultipleSortByFilter.Spec}  ${SortDirection.Asc}`,
      ],
      SelectField: FilterUtils.getNewSelectField(),
      SearchField: ['VehicleMake', 'VehicleModel'],
    };
    const vehicleList = await ListingService.searchAllVehicle(searchPayload);
    setBudgetCarList(vehicleList?.DataList);
    if (vehicleList?.DataList.length === 0) {
      setIsError(true);
      //   setIsSelectEMI(true);
    } else {
      setIsSelectEMI(false);
    }
  };
  const isRTL = useMemo(() => {
    return i18n.language === 'ar';
  }, [i18n.language]);

  const searchAllVehicle = async () => {
    if (totalVehiclePrice) {
      var emivalue = Number(
        ((10 / 100) * Number(totalVehiclePrice)).toFixed(0)
      );
      let appliedFilter: FilterParams = {
        locale: router.locale,
        makes: [],
        models: [],
        page: 1,
        size: 15,
        sortBy: SortByFilter.ListedDate,
        sortDir: SortDirection.Desc,
        multipleSortBy: [MultipleSortByFilter.AskingPrice],
        specs: [],
        type: VehicleAgeType.New,
        vehicleType: [String('New')],
        priceRange: {
          min: Math.round(Number(totalVehiclePrice) - Number(emivalue)),
          max: Math.round(Number(totalVehiclePrice) + Number(emivalue)),
        },
      };

      const { searchPayload, queryString, badges } =
        await NewFilterUtils.getSearchParams({
          ...appliedFilter,
          locale: router.locale,
        });
      router.push(`/all-listings${queryString}`);
    }
  };

  const updateAdditionalExpenses = () => {
    setProfitVal(AdditionalProfitRateVal);
    setInsuredVal(AdditionalInsuranceVal);
    setAdminFeesVal(AdditionalAdminFeesVal);
    setProfitRate(AdditionalProfitRate);
    setInsurance(AdditionalInsurance);
    setAdminFees(AdditionalAdminFees);
    setShow(false);
    getMonthlyEMI(
      Number(EMIValue),
      AdditionalProfitRate,
      AdditionalInsurance,
      AdditionalAdminFees
    );
  };

  useEffect(() => {
    if (!isSelectEMI && budgetCarList.length) {
      var swiperBanner = new Swiper(`.emi-wise-car-price-swiper`, {
        spaceBetween: 8,
        slidesPerView: 7,
        slidesPerGroup: 1,
        grabCursor: false,
        allowTouchMove: false,
        navigation: {
          // nextEl: `.emi-wise-car-price-next-arrow`,
          // prevEl: `.emi-wise-car-price-prev-arrow`,
          disabledClass: 'swiper-button-disabled',
        },
        breakpoints: {
          0: {
            slidesPerView: 2.7,
            slidesOffsetBefore: 16,
            allowTouchMove: true,
          },
          450: {
            slidesPerView: 3.5,
            slidesOffsetBefore: 16,
            allowTouchMove: true,
          },
          639: {
            slidesPerView: 4.5,
            slidesOffsetBefore: 16,
            allowTouchMove: true,
          },
          865: {
            slidesPerView: 5.5,
            slidesOffsetBefore: 16,
            allowTouchMove: true,
          },
          1000: {
            slidesPerView: 7,
            allowTouchMove: false,
          },
        },
      });
    }
    return () => {
      if (swiperBanner && !isSelectEMI) {
        swiperBanner?.destroy();
      }
    };
  }, [i18n.language, budgetCarList, isSelectEMI]);

  const onEMiSubmit = (val: any) => {
    if (
      Number(EMIInitialValue?.MinimumEMIPerMonth) > Number(val) ||
      Number(EMIInitialValue?.MaximumEMIPerMonth) < Number(val)
    ) {
      setBudgetCarList([]);
      setIsError(true);
      setIsSelectEMI(true);
    } else {
      setIsTouched(false);
      getMonthlyEMI(Number(val), profitRate, insurance, adminFees);
    }
  };

  return (
    <>
      <section className="mb-16 theme-v1 py-[34px] lg:py-[83px] bg-[#F7F7F7]">
        <div className="gogo-container">
          <h1 className="mb-[31px] lg:mb-[48px] text-[20px] lg:text-[34px] leading-[25.2px] lg:leading-[42.84px] text-[#212121] font-semibold">
            {LabelConstants?.EMI_CALCULATE?.CALCULATE_YOUR_CAR_BUDGETING}
          </h1>
        </div>
        <div className="gogo-container !pl-0 !pr-0">
          {isSelectEMI && (
            <div className="bg-white shadow-[0px_1px_12px_0px_rgba(0,0,0,0.16)] min-h-[292px] lg:min-h-[240px] rounded-[16px]">
              <div className="py-[32px] px-[38px] lg:px-[40px]">
                <div
                  className={`text-[#000000] text-center font-semibold ${
                    isRTL ? 'lg:text-right' : 'lg:text-left'
                  } text-[15px] leading-[18.9px] lg:text-[24px] lg:leading-[30.24px]`}
                >
                  {LabelConstants?.EMI_CALCULATE?.EMI_YOU_CAN_PAY}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2">
                  <div className="pt-[24px] lg:pt-[46px] lg:px-[25px]">
                    <div className="flex justify-center lg:justify-start items-center relative">
                      <div
                        className={`flex items-center justify-center w-[180px] lg:w-[322px] h-[52px] lg:h-[72px] mx-[16px] border ${
                          isError
                            ? 'bg-[#F9EFEC] border-[#E02E01]'
                            : ' bg-[#F7F1F7] border-[#000000]'
                        } rounded-[2px]`}
                      >
                        <div className="pt-1.5 pointer-events-none w-28 text-end text-[#212121] leading-[18.9px] text-[15px] lg:text-[20px] lg:leading-[25.2px] font-semibold">
                          {LabelConstants.SAR}
                        </div>
                        <div>
                          <input
                            type="type"
                            id="price"
                            inputMode="numeric"
                            className={`w-40 ${
                              isError ? 'bg-[#F9EFEC]' : 'bg-[#F7F1F7]'
                            } border-0 leading-[30.24px] lg:leading-[35.28px] text-[24px] lg:text-[28px] text-[#212121] font-semibold placeholder:text-[#0000004D]`}
                            placeholder="X,XXX"
                            autoComplete="off"
                            value={EMIValue && formatNumber(Number(EMIValue))}
                            onChange={(event: any) => {
                              let userInput = event?.target?.value?.replace(
                                /[^0-9]/gi,
                                ''
                              );
                              setEMIValue(userInput);
                              setIsError(false);
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-[42px] lg:w-[60px] absolute rtl:-ml-[250px] ltr:ml-[250px] lg:relative  lg:ltr:ml-[0px]">
                        {EMIValue && (
                          <button
                            onClick={() => onEMiSubmit(EMIValue)}
                            className="w-[42px] h-[42px] lg:h-[60px] lg:w-[60px] bg-[#4C0055] rounded-[50px] flex justify-center items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              className="rtl:rotate-180"
                            >
                              <path
                                d="M10.9997 20.6429L20.6426 11L10.9997 1.35718"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M1.35686 11H20.6426"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>
                    {isError && (
                      <div className="lg:w-[322px] mx-[16px] text-center text-[#E02E01] font-normal text-[11px] lg:text-[13px] leading-[13.86px] lg:leading-[16.38px] pt-[5px] lg:pt-[10px]">
                        {
                          LabelConstants?.EMI_CALCULATE
                            ?.NO_NEW_CARS_IN_THE_MARKET
                        }
                      </div>
                    )}
                  </div>
                  <div className="pt-[30px]">
                    <div className="lg:hidden text-center font-medium mb-[14px] text-[14px] text-[#000000] leading-[17.64px]">
                      {LabelConstants?.TRY}!
                    </div>
                    <div className="grid grid-cols-3 gap-[7.63px]">
                      {EMIInitialValue &&
                        EMIInitialValue.DefaultEMIMonth &&
                        EMIInitialValue.DefaultEMIMonth.length > 0 &&
                        EMIInitialValue.DefaultEMIMonth.map(
                          (item: any, index) => (
                            <div
                              key={index}
                              className={`min-w-[84px] bg-white lg:mb-[12px] px-[15px] lg:px-[16px] py-[12px] lg:py-[18px] font-bold h-[48px] border border-[#B2B2B2] rounded-[40px] items-center flex justify-center leading-[13.86px] lg:leading-[20.16px] text-[11px] lg:text-[16px] text-[#757575] 
                          lg:hover:bg-[#212121] lg:hover:text-[#FFFFFF] cursor-pointer`}
                              onClick={() => {
                                setEMIValue(item?.Value);
                                setIsSelectEMI(false);
                                setIsError(false);
                                setIsTouched(false);
                                getMonthlyEMI(
                                  Number(item?.Value),
                                  profitRate,
                                  insurance,
                                  adminFees
                                );
                              }}
                            >
                              {LabelConstants?.SAR} {formatNumber(item?.Value)}
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isSelectEMI && (
            <>
              {budgetCarList?.length > 0 && (
                <div className="mx-auto md:px-32 pb-[33px]">
                  <div className="swiper">
                    <div
                      className={`emi-wise-car-price-swiper`}
                      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    >
                      <div className="swiper-wrapper">
                        {budgetCarList.map((item, index) => (
                          <div
                            className="swiper-slide relative"
                            key={index}
                            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                          >
                            <div
                              className="relative w-[122px] lg:w-[122px] lg:h-[122px] bg-[#F8F8F8] flex justify-center"
                              key={index}
                            >
                              <Image
                                alt={'car'}
                                loading="lazy"
                                width={122}
                                height={122}
                                objectFit="cover"
                                className="rounded-[10.17px]"
                                src={
                                  item?.ThumbnailUrl ||
                                  '/images/default-car.jpg'
                                }
                              />
                              <div className="bg-gradient-price absolute w-full px-[12px] py-[10px] bottom-0 font-normal text-white text-[13px] leading-[16.38px]">
                                {LabelConstants.SAR}{' '}
                                {item?.AskingPrice &&
                                  formatNumber(item?.AskingPrice)}
                              </div>
                            </div>
                          </div>
                        ))}
                        <div
                          className="swiper-slide relative !hidden lg:!block"
                          dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                        >
                          <div
                            onClick={() => searchAllVehicle()}
                            className="flex cursor-pointer items-center justify-center w-[122px] h-[122px] border border-[#4C0055] rounded-[12px]"
                          >
                            <div className="font-medium text-[#4C0055] text-[20px] leading-[25.2px]">
                              {LabelConstants?.EMI_CALCULATE?.SHOW_ALL} <br />{' '}
                              <div className="flex items-center">
                                {LabelConstants.CARS}{' '}
                                <ChevronLeftIcon
                                  className={`w-[16px] h-[16px] mt-1 ${
                                    isRTL ? '' : 'rotate-180'
                                  }`}
                                  stroke="#4C0055"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="hidden lg:block lg:text-center text-[#757575] text-[13px] lg:text-[20px] leading-[16.38px] lg:leading-[25.2px] mt-[12px]">
                    {LabelConstants?.EMI_CALCULATE?.CARS_UNDER}{' '}
                    <span>{LabelConstants.SAR}</span> {isRTL && <span>*</span>}
                    {totalVehiclePrice && formatNumber(totalVehiclePrice)}
                    {!isRTL && <span>*</span>}
                  </div>
                  <div className="lg:hidden flex items-center justify-between px-[20px] mt-[16px]">
                    <div className="text-[#757575] text-[13px] leading-[16.38px]">
                      {LabelConstants.CARS_UNDER}
                      {LabelConstants.SAR} {isRTL && <span>*</span>}
                      {totalVehiclePrice && formatNumber(totalVehiclePrice)}
                      {!isRTL && <span>*</span>}
                    </div>
                    <div
                      onClick={() => searchAllVehicle()}
                      className="cursor-pointer flex text-[#4C0055] text-[13px] leading-[16.38px] font-medium  underline underline-offset-1"
                    >
                      {LabelConstants?.EMI_CALCULATE?.SHOW_ALL}{' '}
                      {LabelConstants.CARS}{' '}
                      <ChevronLeftIcon
                        className={`w-[16px] h-[16px] mt-0.5 ${
                          isRTL ? '' : 'rotate-180'
                        }`}
                        stroke="#4C0055"
                      />
                    </div>
                  </div>
                </div>
              )}

              <div>
                <div className="bg-white shadow-[0px_1px_12px_0px_rgba(0,0,0,0.16)] min-h-[186px] rounded-[16px]">
                  <div className="py-[32px] px-[20px] lg:px-[40px]">
                    <div
                      className={`text-[#000000] text-center font-semibold ${
                        isRTL ? 'lg:text-right' : 'lg:text-left'
                      } text-[15px] leading-[18.9px] lg:text-[24px] lg:leading-[30.24px]`}
                    >
                      {LabelConstants?.EMI_CALCULATE?.SET_THE_EMI_YOU_CAN_PAY}
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-8">
                      <div className="pt-[24px] lg:pt-[46px] lg:px-[25px]">
                        <div className="flex justify-center gap-[16px] lg:justify-start items-center relative">
                          <div className="w-[60px] lg:w-[80px]">
                            <div
                              className={`${
                                Number(EMIValue) - 500 <
                                Number(EMIInitialValue?.MinimumEMIPerMonth)
                                  ? 'text-[#E0E0E0] pointer-events-none'
                                  : 'text-[#4C0055] cursor-pointer pointer-events-auto'
                              } ${
                                isTouched ? 'invisible' : 'visible'
                              } bg-white font-bold w-[60px] lg:w-[80px] h-[36px] lg:h-[44px] border border-[#0000004D] rounded-[40px] items-center flex justify-center leading-[13.86px] lg:leading-[18.9px] text-[11px] lg:text-[15px]`}
                              onClick={() => {
                                if (
                                  Number(EMIInitialValue?.MinimumEMIPerMonth) >=
                                  Number(EMIValue)
                                ) {
                                  return;
                                }
                                setEMIValue(
                                  (prevCount: any) => Number(prevCount) - 500
                                );
                                setIsError(false);
                                onEMiSubmit(Number(EMIValue) - 500);
                              }}
                            >
                              - 500
                            </div>
                          </div>
                          <div className="w-[180px] lg:w-[280px]">
                            <div
                              className={`flex items-center justify-center w-[180px] lg:w-[280px] h-[52px] lg:h-[72px] border ${
                                isError
                                  ? 'bg-[#F9EFEC] border-[#E02E01]'
                                  : ' bg-[#F7F1F7] border-[#000000]'
                              } rounded-[2px]`}
                            >
                              <div className="pt-1.5 pointer-events-none w-28 text-end text-[#212121] leading-[18.9px] text-[15px] lg:text-[20px] lg:leading-[25.2px] font-semibold">
                                {LabelConstants.SAR}
                              </div>
                              <div>
                                <input
                                  type="type"
                                  id="price"
                                  inputMode="numeric"
                                  autoComplete="off"
                                  className={`w-40 ${
                                    isError ? 'bg-[#F9EFEC]' : 'bg-[#F7F1F7]'
                                  } border-0 leading-[30.24px] lg:leading-[35.28px] text-[24px] lg:text-[28px] text-[#212121] font-semibold placeholder:text-[#0000004D]`}
                                  placeholder="X,XXX"
                                  value={
                                    EMIValue && formatNumber(Number(EMIValue))
                                  }
                                  onChange={(event: any) => {
                                    let userInput =
                                      event?.target?.value?.replace(
                                        /[^0-9]/gi,
                                        ''
                                      );
                                    setEMIValue(userInput);
                                    setIsError(false);
                                    setIsTouched(true);
                                    if (!userInput) {
                                      setIsSelectEMI(true);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={`w-[60px] lg:w-[80px]`}>
                            <div
                              className={` ${
                                isTouched ? 'hidden' : 'visible'
                              } cursor-pointer bg-white font-bold w-[60px] lg:w-[80px] h-[36px] lg:h-[44px] border border-[#0000004D] rounded-[40px] items-center flex justify-center leading-[13.86px] lg:leading-[18.9px] text-[11px] lg:text-[15px] text-[#4C0055]`}
                              onClick={() => {
                                if (
                                  Number(EMIInitialValue?.MaximumEMIPerMonth) <=
                                  Number(EMIValue)
                                ) {
                                  return;
                                }
                                setEMIValue(
                                  (prevCount: any) => Number(prevCount) + 500
                                );
                                setIsError(false);
                                onEMiSubmit(Number(EMIValue) + 500);
                              }}
                            >
                              + 500
                            </div>

                            <div
                              className={`${
                                EMIValue && isTouched ? 'visible' : 'hidden'
                              } w-[42px] lg:w-[60px]`}
                            >
                              {/* w-[42px] lg:w-[60px] absolute rtl:-ml-[250px] ltr:ml-[0] lg:relative  lg:ltr:ml-[0px] */}
                              <button
                                onClick={() => onEMiSubmit(EMIValue)}
                                className="w-[42px] h-[42px] lg:h-[60px] lg:w-[60px] bg-[#4C0055] rounded-[50px] flex justify-center items-center"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  viewBox="0 0 22 22"
                                  fill="none"
                                  className="rtl:rotate-180"
                                >
                                  <path
                                    d="M10.9997 20.6429L20.6426 11L10.9997 1.35718"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M1.35686 11H20.6426"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                        {isError && (
                          <div className="mx-[16px] text-center text-[#E02E01] font-normal text-[11px] lg:text-[13px] leading-[13.86px] lg:leading-[16.38px] pt-[5px] lg:pt-[10px]">
                            {
                              LabelConstants?.EMI_CALCULATE
                                ?.NO_NEW_CARS_IN_THE_MARKET
                            }
                          </div>
                        )}
                        {budgetCarList?.length > 0 && (
                          <div className="mx-[16px] text-center text-[#757575] font-normal text-[13px] lg:text-[16px] leading-[16.38px] lg:leading-[20.16px] pt-[2px] lg:pt-[16px]">
                            {LabelConstants?.EMI_CALCULATE?.LOAN_AMOUNT}{' '}
                            {LabelConstants.SAR}{' '}
                            {totalEMIWiseLoan && formatNumber(totalEMIWiseLoan)}
                          </div>
                        )}
                      </div>

                      <div className="">
                        <div className="md:text-center pt-[10px] lg:pt-[5px] text-[#212121] font-semibold text-[15px] lg:text-[20px] leading-[18.9px] lg:leading-[25.2px]">
                          {LabelConstants?.EMI_CALCULATE?.DURATION_IN_YEARS}
                        </div>
                        <div className="flex gap-[18px] pt-[12px] lg:pt-[31px] md:justify-center">
                          {[...Array(EMIInitialValue?.NumberOfYears)].map(
                            (item: any, index) => (
                              <div
                                key={index}
                                className={`font-medium w-[54px] lg:w-[72px] h-[40px] lg:h-[50px] border border-[#B2B2B2] rounded-[6px] items-center flex justify-center leading-[16.38px] lg:leading-[25.2px] text-[13px] lg:text-[20px] hover:bg-[#212121] hover:text-[#FFFFFF] cursor-pointer ${
                                  tenure === index + 1
                                    ? 'bg-black text-white'
                                    : 'bg-white text-[#757575]'
                                }`}
                                onClick={() => {
                                  setTenure(index + 1);
                                  setIsError(false);
                                }}
                              >
                                {index + 1}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {budgetCarList?.length > 0 && (
                <div className="pt-[32px] lg:pt-[54px] px-[20px] lg:px-[36px]">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-[32px] lg:gap-[73px]">
                    <div>
                      <div className="hidden lg:inline font-semibold text-[#000000] text-[15px] lg:text-[20px] leading-[18.9px] lg:leading-[25.2px]">
                        {LabelConstants.DOWN_PAYMENT}
                      </div>
                      <div className="flex items-center justify-between mt-[0px] lg:mt-[16px]">
                        <div>
                          <div className="pb-[4px] inline lg:hidden font-semibold text-[#000000] text-[15px] lg:text-[20px] leading-[18.9px] lg:leading-[25.2px]">
                            {LabelConstants.DOWN_PAYMENT}
                          </div>
                          <div className="text-[#000000] font-[11px] lg:font-[13px] leading-[13.86px] lg:leading-[16.38px] font-semibold">
                            {downPayment}%
                          </div>
                        </div>
                        <div
                          className={`bg-white px-[10px] py-[12px] font-medium min-w-[92px] lg:min-w-[120px] h-[36px] lg:h-[44px] border border-[#000000] rounded-[4px] items-center flex justify-center leading-[16.38px] lg:leading-[20.16px] text-[13px] lg:text-[16px] text-[#000000]`}
                        >
                          {LabelConstants.SAR}{' '}
                          {downPaymentVal && formatNumber(downPaymentVal)}
                        </div>
                      </div>
                      <div className="pt-[14px] lg:pt-[18px]">
                        <Slider
                          onChange={(nextValues: any) => {
                            setDownPayment(nextValues);
                          }}
                          reverse={isRTL ? true : false}
                          min={EMIInitialValue?.DownPaymentMinimumPercentage}
                          max={EMIInitialValue?.DownPaymentMaximumPercentage}
                          value={downPayment}
                          step={EMIInitialValue?.DownPaymentPercentageStep}
                        />
                        <div className="flex justify-between text-[#757575] font-[13px] leading-[16.38px] font-normal mt-[12px]">
                          <span>
                            {EMIInitialValue?.DownPaymentMinimumPercentage}%
                          </span>
                          <span>
                            {EMIInitialValue?.DownPaymentMaximumPercentage}%
                          </span>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="hidden lg:inline font-semibold text-[#000000] text-[15px] lg:text-[20px] leading-[18.9px] lg:leading-[25.2px]">
                        {LabelConstants?.EMI_CALCULATE?.BALLOON_PAYMENT}
                      </div>
                      <div className="flex items-center justify-between mt-[0px] lg:mt-[16px]">
                        <div>
                          <div className="pb-[4px] inline lg:hidden font-semibold text-[#000000] text-[15px] lg:text-[20px] leading-[18.9px] lg:leading-[25.2px]">
                            {LabelConstants?.EMI_CALCULATE?.BALLOON_PAYMENT}
                          </div>
                          <div className="text-[#000000] font-[11px] lg:font-[13px] leading-[13.86px] lg:leading-[16.38px] font-semibold">
                            {bollonPayment}%
                          </div>
                        </div>
                        <div
                          className={`bg-white px-[10px] py-[12px] font-medium min-w-[92px] lg:min-w-[120px] h-[36px] lg:h-[44px] border border-[#000000] rounded-[4px] items-center flex justify-center leading-[16.38px] lg:leading-[20.16px] text-[13px] lg:text-[16px] text-[#000000]`}
                        >
                          {LabelConstants.SAR}{' '}
                          {balloonPaymentVal && formatNumber(balloonPaymentVal)}
                        </div>
                      </div>
                      <div className="pt-[14px] lg:pt-[18px]">
                        <Slider
                          onChange={(nextValues: any) => {
                            setBollonPayment(nextValues);
                          }}
                          reverse={isRTL ? true : false}
                          min={EMIInitialValue?.BalloonPaymentMinimumPercentage}
                          max={EMIInitialValue?.BalloonPaymentMaximumPercentage}
                          value={bollonPayment}
                          step={EMIInitialValue?.BalloonPaymentPercentageStep}
                        />
                        <div className="flex justify-between text-[#757575] font-[13px] leading-[16.38px] font-normal mt-[12px]">
                          <span>
                            {EMIInitialValue?.BalloonPaymentMinimumPercentage}%
                          </span>
                          <span>
                            {EMIInitialValue?.BalloonPaymentMaximumPercentage}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {budgetCarList?.length > 0 && (
                <div
                  dir={direction}
                  className="lg:flex justify-center items-center bg-[#ffff] lg:bg-[#F5F5F5] mt-[28px] lg:mt-[48px] lg:w-[56%] mx-[20px] lg:mx-auto  px-[12px] py-[18px] border-[#0000001F] border rounded-[4px]"
                >
                  <div className="text-[#000000] leading-[16.38px] lg:leading-[20.16px] text-[13px] lg:text-[16px] font-medium">
                    {LabelConstants?.EMI_CALCULATE?.ADDITIONAL_DETAILS}:{' '}
                    {LabelConstants.SAR}{' '}
                    {profitVal &&
                      insuredVal &&
                      adminFeesVal &&
                      formatNumber(profitVal + insuredVal + adminFeesVal)}
                  </div>{' '}
                  <div className="flex mt-[8px] items-center lg:mt-[0]">
                    <div className="flex text-[#757575] leading-[13.86px] lg:leading-[16.38px]  text-[11px] lg:text-[13px] font-normal lg:mt-[1px] mx-[0px] lg:mx-[5px]">
                      <div className="mx-[2px]">
                        {' '}
                        {LabelConstants?.EMI_CALCULATE?.PROFIT_RATE}
                      </div>{' '}
                      <div> {profitRate}%, </div>
                      <div className="mx-[2px]">
                        {' '}
                        {LabelConstants?.EMI_CALCULATE?.INSURANCE}
                      </div>{' '}
                      <div> {insurance}%, </div>
                      <div className="mx-[2px]">
                        {' '}
                        {LabelConstants?.EMI_CALCULATE?.ADMIN_FEE}
                      </div>{' '}
                      <div> {adminFees}% </div>
                    </div>{' '}
                    <div
                      onClick={() => {
                        setAdditionalProfitRate(profitRate);
                        setAdditionalInsurance(insurance);
                        setAdditionalAdminFees(adminFees);
                        setAdditionalProfitRateVal(profitVal);
                        setAdditionalInsuranceVal(insuredVal);
                        setAdditionalAdminFeesVal(adminFeesVal);
                        setShow(true);
                      }}
                      className="text-[#000000] leading-[13.86px] lg:leading-[20.16px] text-[11px] lg:text-[16px] font-normal mx-[5px] underline cursor-pointer"
                    >
                      {LabelConstants?.EMI_CALCULATE?.SHOW_DATAILS}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>

      <Modal
        show={show}
        onClose={() => setShow(false)}
        containerClassName="w-full lg:!w-[400px] bottom-0 additional-expenses-modal !px-0"
      >
        <ModalBody>
          <section className="pt-[60px]">
            <div className="flex justify-between mb-[34px]">
              <div className="text-[#212121] text-[18px] lg:text-[21px] leading-[22.68px] lg:leading-[26.46px] font-medium">
                {LabelConstants?.EMI_CALCULATE?.ADDITIONAL_DETAILS}
              </div>
              <div className="text-[#212121] text-[18px] lg:text-[21px] leading-[22.68px] leading-[26.46px] font-medium">
                {LabelConstants.SAR}{' '}
                {AdditionalProfitRateVal &&
                  AdditionalInsuranceVal &&
                  AdditionalAdminFeesVal &&
                  formatNumber(
                    AdditionalProfitRateVal +
                      AdditionalInsuranceVal +
                      AdditionalAdminFeesVal
                  )}
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between mt-[16px]">
                <div className="flex text-[#212121] font-[13px] leading-[15.73px] font-semibold">
                  <div> {LabelConstants?.EMI_CALCULATE?.PROFIT_RATE} </div>
                  <div className="mx-[2px]">{AdditionalProfitRate}%</div>
                </div>
                <div
                  className={`bg-white py-[10px] px-[12px] font-medium min-w-[97px] h-[36px] border border-[#B2B2B2] rounded-[4px] items-center flex justify-center leading-[16.38px] text-[13px] text-[#212121]`}
                >
                  {LabelConstants.SAR}{' '}
                  {AdditionalProfitRateVal &&
                    formatNumber(AdditionalProfitRateVal)}
                </div>
              </div>
              <div className="pt-[10px]">
                <Slider
                  onChange={(nextValues) => {
                    updateAdditionalProfitRate(nextValues);
                  }}
                  reverse={isRTL ? true : false}
                  min={EMIInitialValue?.ProfitRateMinimumPercentage}
                  max={EMIInitialValue?.ProfitRateMaximumPercentage}
                  value={AdditionalProfitRate}
                  step={EMIInitialValue?.ProfitRatePercentageStep}
                />
                <div className="flex justify-between text-[#757575] font-[13px] leading-[16.38px] font-normal mt-[8px]">
                  <span>{EMIInitialValue?.ProfitRateMinimumPercentage}%</span>
                  <span>{EMIInitialValue?.ProfitRateMaximumPercentage}%</span>
                </div>
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between mt-[16px]">
                <div className="flex text-[#212121] font-[13px] leading-[15.73px] font-semibold">
                  <div> {LabelConstants?.EMI_CALCULATE?.INSURANCE} </div>
                  <div className="mx-[2px]">{AdditionalInsurance}%</div>
                </div>
                <div
                  className={`bg-white py-[10px] px-[12px] font-medium min-w-[97px] h-[36px] border border-[#B2B2B2] rounded-[4px] items-center flex justify-center leading-[16.38px] text-[13px] text-[#212121]`}
                >
                  {LabelConstants.SAR}{' '}
                  {AdditionalInsuranceVal &&
                    formatNumber(AdditionalInsuranceVal)}
                </div>
              </div>
              <div className="pt-[10px]">
                <Slider
                  onChange={(nextValues) => {
                    updateAdditionalInsurance(nextValues);
                  }}
                  reverse={isRTL ? true : false}
                  min={EMIInitialValue?.InsuranceMinimumPercentage}
                  max={EMIInitialValue?.InsuranceMaximumPercentage}
                  value={AdditionalInsurance}
                  step={EMIInitialValue?.InsurancePercentageStep}
                />
                <div className="flex justify-between text-[#757575] font-[13px] leading-[16.38px] font-normal mt-[8px]">
                  <span>{EMIInitialValue?.InsuranceMinimumPercentage}%</span>
                  <span>{EMIInitialValue?.InsuranceMaximumPercentage}%</span>
                </div>
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between mt-[16px]">
                <div className="flex text-[#212121] font-[13px] leading-[15.73px] font-semibold">
                  <div> {LabelConstants?.EMI_CALCULATE?.ADMIN_FEE} </div>
                  <div className="mx-[2px]">{AdditionalAdminFees}%</div>
                </div>
                <div
                  className={`bg-white py-[10px] px-[12px] font-medium min-w-[97px] h-[36px] border border-[#B2B2B2] rounded-[4px] items-center flex justify-center leading-[16.38px] text-[13px] text-[#212121]`}
                >
                  {LabelConstants.SAR}{' '}
                  {AdditionalAdminFeesVal &&
                    formatNumber(AdditionalAdminFeesVal)}
                </div>
              </div>

              <div className="pt-[10px]">
                <Slider
                  onChange={(nextValues) => {
                    updateAdditionalAdminFees(nextValues);
                  }}
                  reverse={isRTL ? true : false}
                  min={EMIInitialValue?.AdminFeesMinimumPercentage}
                  max={EMIInitialValue?.AdminFeesMaximumPercentage}
                  value={AdditionalAdminFees}
                  step={EMIInitialValue?.AdminFeesPercentageStep}
                />
                <div className="flex justify-between text-[#757575] font-[13px] leading-[16.38px] font-normal mt-[8px]">
                  <span>{EMIInitialValue?.AdminFeesMinimumPercentage}%</span>
                  <span>{EMIInitialValue?.AdminFeesMaximumPercentage}%</span>
                </div>
              </div>
            </div>

            <div className="py-[20px]">
              <button
                onClick={() => updateAdditionalExpenses()}
                className=" text-[13px] font-semibold leading-[16.38px] flex justify-center items-center text-white bg-[#000000] h-[16px] w-full px-[16px] py-[30px] rounded-[40px]"
              >
                {LabelConstants?.EMI_CALCULATE?.UPDATE}{' '}
              </button>
            </div>
          </section>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EmiCalculator;
