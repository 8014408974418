import { NextPage } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useAppSelector } from '../../lib/hooks';
import { City } from '../../types/models';
import { useCityContext } from '../../provider/CityProvider';

type BrowseByLocationProps = {
  topCities: Array<City>;
};
const customLoader = ({ src, width, quality }: any) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};
const BrowseByLocation: NextPage<BrowseByLocationProps> = ({ topCities }) => {
  const router = useRouter();
  const cleverTap = useAppSelector(({ global }) => global.clevertap);
  const [locations, setLocations] = useState<Array<City>>(
    topCities.slice(0, 9) || []
  );
  const { cities, selectCity, setSelectCity } = useCityContext();

  useEffect(() => {
    setLocations(isMobileOnly ? topCities.slice(0, 6) : topCities.slice(0, 8));
  }, [isMobileOnly, topCities]);

  return (
    <>
      <div className="grid grid-cols-3 lg:grid-cols-4 gap-8">
        {locations.map((item, index) => (
          <div key={index} tabIndex={810 + index}>
            <div
              className="cursor-pointer"
              onClick={() => {
                router.push(`/all-listings?city=${item?.CityKey}`);
                cleverTap.event.push(`hp_location_${item?.City}_selected`);
                setSelectCity(item);
                localStorage.setItem('city', JSON.stringify(item));
              }}
            >
              <div className="relative rounded-[8px] lg:rounded-none">
                <Image
                  className="rounded-[8px] lg:rounded-none"
                  loader={customLoader}
                  loading="lazy"
                  src={item.ImageUrlPath}
                  alt={item.City}
                  width={270}
                  height={142}
                />
              </div>

              <div className="mt-[8px] text-black font-medium text-[13px] lg:text-[20px]">
                {item.City}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default BrowseByLocation;
