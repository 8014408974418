import { SubmitHandler, useForm } from 'react-hook-form';
import { Brand, FilterParams } from '../../types/models';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../common/TabsNew';
import { SearchIcon } from '../icons';
import { useRouter } from 'next/router';
import Link from 'next/link';
import FormInput, { FormDropdown } from '../common/Form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Image from 'next/image';
import { useState } from 'react';
import { NewFilterUtils } from '../../helpers/utilities';
import {
  MultipleSortByFilter,
  SortByFilter,
  SortDirection,
  VehicleAgeType,
} from '../../types/enums';
import GogoLoader from '../common/Loader/Loader';
import { useTranslationContext } from '../../provider/TranslationProvider';

type PropType = {
  topBrands: Array<Brand>;
  filters?: FilterParams;
  direction: string;
};

interface ISearchBoxForm {
  searchKey: string;
  price: any;
}

const schema = yup.object({
  // searchKey: yup.string().required(),
  searchKey: yup.string(),
  price: yup.mixed(),
});

const SearchWidget: React.FC<PropType> = (props) => {
  const { topBrands } = props;
  const [isLoader, setIsLoader] = useState(false);
  // const { register, handleSubmit } = useForm<ISearchBoxForm>();
  const { LabelConstants } = useTranslationContext();
  const router = useRouter();
  const [carType, setCarType] = useState('PreOwned');
  const priceRange = [
    {
      label: `${LabelConstants?.UNDER} ${LabelConstants?.SAR} 75,000`,
      price: '0,75000',
    },
    {
      label: `${LabelConstants?.SAR} 75,000 - 1,30,000`,
      price: '75000,130000',
    },
    {
      label: `${LabelConstants?.SAR} 1,30,000 - 2,00,000`,
      price: '130000,200000',
    },
    {
      label: `${LabelConstants?.SAR} 2,00,000 - 2,50,000`,
      price: '200000,250000',
    },
    {
      label: `${LabelConstants?.SAR} 2,50,000+`,
      price: '250000,3000000',
    },
  ];

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, isDirty },
    getValues,
    reset,
  } = useForm<ISearchBoxForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const searchSubmit: SubmitHandler<ISearchBoxForm> = async (
    data: ISearchBoxForm
  ) => {
    let priceobj = data && data.price && data.price.price;
    if (priceobj || (data && data.searchKey)) {
      setIsLoader(true);
      let appliedFilter: FilterParams = {
        locale: router.locale,
        makes: [],
        models: [],
        page: 1,
        searchKey: data.searchKey,
        size: 15,
        sortBy: SortByFilter.ListedDate,
        sortDir: SortDirection.Asc,
        multipleSortBy: [MultipleSortByFilter.AskingPrice],
        specs: [],
        type: VehicleAgeType.All,
        vehicleType: [String(carType)],
      };

      if (priceobj) {
        const priceobjSplit = priceobj.split(',');
        appliedFilter['priceRange'] = {
          min: priceobjSplit[0],
          max: priceobjSplit[1],
        };
      }
      const { searchPayload, queryString, badges } =
        await NewFilterUtils.getSearchParams({
          ...appliedFilter,
          locale: router.locale,
        });
      setIsLoader(false);
      router.push(`/all-listings${queryString}`);
    }
  };

  return (
    <>
      {isLoader && <GogoLoader />}
      <section className="relative z-50 hidden lg:block">
        <div className="gogo-container mx-auto">
          <div className="flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-[0_4px_14px_0px_rgba(0,0,0,0.12)] -mt-40">
            <div className="py-8">
              <div className="grid grid-cols-1 gap-8">
                <div className="px-20 col-span-1">
                  <Tabs defaultValue={carType}>
                    <TabsList className="box-border !border-b-0 mb-8 !justify-center">
                      <TabsTrigger
                        value="New"
                        className="text-lg mx-5 text-gray-100 data-[state=active]:text-[#3A3A3C] data-[state=active]:font-medium data-[state=active]:!border-[#3A3A3C]"
                        id="tab-1"
                        role="tab"
                        aria-selected={carType === 'New'}
                        aria-controls="tabpanel-1"
                        onClick={() => {
                          setCarType('New');
                        }}
                        tabIndex={90}
                      >
                        {LabelConstants?.NEW_CAR}
                      </TabsTrigger>
                      <TabsTrigger
                        value="PreOwned"
                        className="text-lg mx-5 text-gray-100 data-[state=active]:text-[#3A3A3C] data-[state=active]:font-medium data-[state=active]:!border-[#3A3A3C]"
                        aria-selected={carType === 'PreOwned'}
                        aria-controls="tabpanel-2"
                        role="tab"
                        id="tab-2"
                        onClick={() => {
                          setCarType('PreOwned');
                        }}
                        tabIndex={91}
                      >
                        {LabelConstants?.PRE_OWNED_CAR}
                      </TabsTrigger>
                    </TabsList>
                    <TabsContent
                      className="TabsContent mt-0"
                      value="new-car"
                      id="tabpanel-2"
                      role="tabpanel"
                      tabIndex={92}
                      aria-labelledby="tab-2"
                    ></TabsContent>
                    <form onSubmit={handleSubmit(searchSubmit)}>
                      <div
                        className="grid grid-cols-12 gap-8 search-widget"
                        dir={props?.direction}
                      >
                        <div className="col-span-10">
                          <div className="search-form">
                            <div className="bg-[#F3F2F4] flex items-center  justify-center border-none w-full h-[4.1rem]">
                              <div className="pl-[30px] pr-[15px] rtl:pl-[15px] rtl:pr-[30px]">
                                <SearchIcon className="h-[1.2rem] w-[1.2rem] text-[#8F90A6]" />
                              </div>
                              <FormInput
                                tabIndex={93}
                                className="h-full px-0 border-none  text-2xl leading-7 bg-[#F3F2F4] placeholder:font-normal placeholder:text-[18px] placeholder:text-[#8F90A6]"
                                name="searchKey"
                                label=""
                                placeholder={
                                  LabelConstants?.SEARCH_MAKE_MODEL_TYPE
                                }
                                control={control}
                                pattern={/[^A-Za-z0-9-_ ]+/}
                              />

                              <div className="w-[400px] flex items-center gap-8">
                                <div className="border-l h-8 border-[#8D8D8E]"></div>
                                <div className="gogo-price-range flex items-center gap-1">
                                  <FormDropdown
                                    tabIndex={94}
                                    options={priceRange}
                                    labelAccessor="label"
                                    valueAccessor="price"
                                    placeHolderText={
                                      LabelConstants?.PRICE_RANGE
                                    }
                                    labelText={''}
                                    control={control}
                                    name="price"
                                    isSearchable={false}
                                    className="cursor-pointer"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2">
                          <button
                            tabIndex={95}
                            type="submit"
                            // disabled={!isValid || !isDirty}
                            className="h-[4.1rem] w-full bg-[#3A3A3C] text-white text-lg font-medium outline-none focus:outline-none"
                          >
                            {LabelConstants?.SEARCH}
                          </button>
                        </div>
                      </div>
                    </form>
                    {/* </TabsContent> */}
                  </Tabs>
                </div>
                <div className="col-span-1">
                  <div className="h-[1px]  bg-[#EBEBF0] w-full"></div>
                </div>
                <div className="px-20 col-span-1">
                  <h1 className="mb-2 text-base font-normal text-[#3A3A3C]">
                    {LabelConstants?.EXPLORE_BY_BRAND}
                  </h1>
                  <div className="grid grid-cols-10 gap-4">
                    {topBrands.map((item, index) => (
                      <Link
                        href={`/all-listings?make=${item.VehicleMakeKey?.toLowerCase()}`}
                        key={index}
                      >
                        <a tabIndex={96 + (index + 1)}>
                          <div className="flex justify-center items-center w-[64px] h-[64px]">
                            {/* {item.ImageUrlPath ? ( */}
                            <div className="relative w-[64px] h-[64px]">
                              <Image
                                loading="lazy"
                                alt={item.VehicleMake}
                                src={
                                  item.LogoUrl
                                    ? `${process.env.NEXT_PUBLIC_Image_CDN_URL}/${item.LogoUrl}`
                                    : `${process.env.NEXT_PUBLIC_Image_CDN_URL}/${item.BlackAndWhiteLogo}`
                                }
                                layout="fill"
                                objectFit="contain" // Scale your image down to fit into the container
                              />
                            </div>
                            {/* ) : (
                              // <picture>
                              //   <img
                              //     src={item.ImageUrlPath}
                              //     className="object-contain w-[64px] h-[64px]"
                              //     alt={item.VehicleMake}
                              //   />
                              // </picture>
                              <div className="flex justify-center items-center w-[64px] h-[64px]">
                                {item.VehicleMake}
                              </div>
                            )} */}
                          </div>
                        </a>
                      </Link>
                    ))}
                    <div className="flex justify-center items-center h-[64px] w-[64px] text-base font-normal text-black underline underline-offset-4">
                      <Link href={`/newcars/new`}>
                        <a tabIndex={120}>{LabelConstants?.SEE_ALL}</a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchWidget;
