import React, { useCallback, useEffect, useState } from 'react';
import { ListingService } from '../../helpers/services';
import { CommonUtils, FilterUtils } from '../../helpers/utilities';
import { useRouter } from 'next/router';
import { SortByFilter, SortDirection } from '../../types/enums';
import {
  BodyType,
  City,
  SearchRequestPayload,
  SearchResultItem,
} from '../../types/models';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../common/TabsNew';
import SwiperCarousel from './SwiperCarousel';
import { useTranslation } from 'next-i18next';
import { useAppSelector } from '../../lib/hooks';
import { useTranslationContext } from '../../provider/TranslationProvider';

interface PropType {
  label?: string;
  cities?: Array<City>;
  bodyTypes: Array<BodyType>;
  selectCityId?: string;
}

const TopSearchedUsedCars: React.FC<PropType> = ({
  label = '',
  cities,
  bodyTypes,
  selectCityId,
}) => {
  const { i18n } = useTranslation();
  const { LabelConstants } = useTranslationContext();
  const router = useRouter();
  const cleverTap = useAppSelector(({ global }) => global.clevertap);
  const [topSearchedCars, setTopSearchedCars] = useState<
    Array<SearchResultItem>
  >([]);
  const [currentTab, setCurrentTab] = useState<string>('');
  const [
    isLoadingTopSearchedPreOwnedList,
    setIsLoadingTopSearchedPreOwnedList,
  ] = useState(false);

  const fetchTopSearchedPreOwnedCarsList = useCallback(async () => {
    if (selectCityId) {
      setIsLoadingTopSearchedPreOwnedList(true);
      let searchPayload: SearchRequestPayload = {
        LanguageID: CommonUtils.getLanguageId(router.locale!),
        IndexName: '',
        Size: 10,
        Page: 1,
        Filter: {
          wildcard: '',
          term: [
            `CityId=${selectCityId}`,
            `BodyTypeId=${currentTab}`,
            'IsNew=False',
            'IsOutlet=False',
          ],
          range: [],
          csv: [],
        },
        OrderBy: [`${SortByFilter.ListedDate}  ${SortDirection.Desc}`],
        SelectField: FilterUtils.getSelectField(),
        SearchField: ['VehicleMake', 'VehicleModel'],
      };
      try {
        let vehicleList = await ListingService.searchVehicle(searchPayload);
        setTopSearchedCars(vehicleList.DataList);
        setIsLoadingTopSearchedPreOwnedList(false);
      } catch (error) {
        setIsLoadingTopSearchedPreOwnedList(false);
        // console.error(`Error in VehicleService:fetchListingImages`, error);
      }
    }
  }, [router.locale, currentTab, selectCityId]);

  useEffect(() => {
    const init = async () => {
      fetchTopSearchedPreOwnedCarsList();
    };
    init();
  }, [fetchTopSearchedPreOwnedCarsList]);

  return (
    <div className="max-w-full theme-v1">
      <h1 className="text-[16px] sm:text-[32px] mb-[20px] md:mb-[36px] font-semibold">
        {label}
      </h1>
      <Tabs
        onValueChange={(value) => setCurrentTab(value)}
        defaultValue={currentTab}
        className="w-full"
        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
      >
        <TabsList className="box-border w-full gogo-motor-scroll-move">
          <TabsTrigger
            value=""
            className="!h-[28px] lg:!h-[38px] text-[12px] lg:text-[15px] px-3 lg:px-6 text-gray-100 data-[state=active]:text-[#3A3A3C] data-[state=active]:font-medium data-[state=active]:border-[#3A3A3C]"
            onClick={() => {
              cleverTap.event.push(`hp_used_car_category_selected`);
            }}
            id="tab-0"
            aria-selected={currentTab === ''}
            aria-controls="tabpanel-0"
            role="tab"
            aria-hidden="true"
            tabIndex={500}
          >
            {LabelConstants?.ALL}
          </TabsTrigger>
          {bodyTypes.map((item, index) => (
            <TabsTrigger
              key={item.BodyTypeId}
              value={item.BodyTypeId.toString()}
              className="!h-[28px] lg:!h-[38px] text-[12px] lg:text-[15px] px-3 lg:px-6 text-gray-100 data-[state=active]:text-[#3A3A3C] data-[state=active]:font-medium data-[state=active]:border-[#3A3A3C]"
              onClick={() => {
                cleverTap.event.push(`hp_used_car_category_selected`);
              }}
              id={`tab-${index + 1}`}
              aria-selected={currentTab === item.BodyTypeId.toString()}
              aria-controls={`tabpanel-${index + 1}`}
              role="tab"
              tabIndex={501 + index}
            >
              {item.BodyType}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>

      <div className="mt-5 md:mt-9">
        <SwiperCarousel
          id="top-searched-used-cars-id"
          silderData={topSearchedCars}
          isFeatures={true}
          isSpecifications={true}
          isCity={true}
          isAskingPrice={true}
          className="min-h-[300px] md:min-h-[387px]"
          cities={cities}
          cleverName="hp_used_car_clicked"
          isLoading={isLoadingTopSearchedPreOwnedList}
          tabIndex={550}
        />
        {!isLoadingTopSearchedPreOwnedList && topSearchedCars.length === 0 && (
          <p className="text-center text-lg">{LabelConstants?.NO_CARS_TEXT}</p>
        )}
      </div>
    </div>
  );
};

export default TopSearchedUsedCars;
