// CarCard.tsx
import React, { useEffect, useState } from 'react';
import { City, ProductType, SearchResultItem } from '../../types/models';
import Link from 'next/link';
import { BookmarkIconNew, LocationIcon2, ShieldCheckIcon } from '../icons';
import { formatNumber, SessionUtils } from '../../helpers/utilities';
import { useRouter } from 'next/router';
import { useAppSelector } from '../../lib/hooks';
import Image from 'next/image';
import GetNotified from './GetNotified';
import { VehicleService } from '../../helpers/services';
import { PushDataToGTM } from '../../helpers/utilities/gtm';
import { GTMEvents } from '../../types/gtm';
import { SignInRedirectType } from '../../types/constants';
import { useTranslationContext } from '../../provider/TranslationProvider';
import { useTranslation } from 'next-i18next';

const customLoader = ({ src, width, quality }: any) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};
interface Vehicle {
  vehicle: any;
  city?: City;
  className?: string;
  isFeatures?: boolean;
  isSpecifications?: boolean;
  isEstimatedPrice?: boolean;
  isAskingPrice?: boolean;
  isCity?: boolean;
  isCheckOnRoadPrice?: boolean;
  badgeText?: string;
  isSideAssistance?: boolean;
  isEmiStartingFrom?: boolean;
  isNotified?: boolean;
  isBudget?: boolean;
  isLink?: boolean;
  cleverName?: string;
  setVehicle?: any;
  bookmarkIds?: Array<number>;
  productCatalogueBookmarkIds?: Array<number>;
  setSignInRequired: () => void;
  onChangeBookmark: () => void;
  height?: string;
  tabIndex?: number;
}

const VehicleCard: React.FC<Vehicle> = ({
  vehicle,
  city,
  className = ``,
  isFeatures = false,
  isSpecifications = false,
  isEstimatedPrice = false,
  isAskingPrice = false,
  isCity = false,
  isCheckOnRoadPrice = false,
  badgeText = '',
  isSideAssistance = false,
  isEmiStartingFrom = false,
  isNotified = false,
  isBudget = false,
  isLink = true,
  cleverName = '',
  setVehicle,
  bookmarkIds = [],
  productCatalogueBookmarkIds = [],
  setSignInRequired,
  onChangeBookmark,
  height,
  tabIndex,
}) => {
  const { i18n } = useTranslation();
  const { LabelConstants } = useTranslationContext();
  const router = useRouter();
  const cleverTap = useAppSelector(({ global }) => global.clevertap);
  const [isFav, setIsFav] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>();
  const [openSellerDetailsModal, setOpenSellerDetailsModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      bookmarkIds &&
      bookmarkIds.length > 0 &&
      vehicle.Type === ProductType.VehicleListing
    ) {
      setIsFav(
        bookmarkIds.some((x) => String(x) === String(vehicle.VehicleListingId))
      );
    } else if (
      productCatalogueBookmarkIds &&
      productCatalogueBookmarkIds.length > 0 &&
      vehicle.Type === ProductType.ProductCatalogue
    ) {
      setIsFav(
        productCatalogueBookmarkIds.some(
          (x) => String(x) === String(vehicle.ProductCatalogueId)
        )
      );
    } else {
      setIsFav(false);
    }

    const user = SessionUtils.getUserDetails();
    if (user) {
      setUserId(user.UserId);
    }
  }, [bookmarkIds, productCatalogueBookmarkIds, vehicle]);

  useEffect(() => {
    const handleAuth = () => {
      const isPageActive = !document.hidden;
      if (isPageActive) {
        const isAuthenticated = SessionUtils.isValidSession();
        if (!isAuthenticated && openSellerDetailsModal) {
          setSignInRequired();
          setOpenSellerDetailsModal(false);
        }
      }
    };
    document.addEventListener('visibilitychange', handleAuth);
    return () => {
      document.removeEventListener('visibilitychange', handleAuth);
    };
  }, [setSignInRequired, openSellerDetailsModal]);

  const bookmarkHandler = async () => {
    if (SessionUtils.isValidSession()) {
      if (vehicle.Type === ProductType.VehicleListing) {
        if (isFav) {
          await VehicleService.deleteBookmark({
            VehicleListingID: vehicle.VehicleListingId!,
          });
          //Added GTM event for Removed BookMark Click
          PushDataToGTM(GTMEvents.RemovedBookmark, {
            vehicleListingId:
              process.env.NEXT_PUBLIC_GTM_ASSET_ENV_PREFIX! +
              vehicle.VehicleListingId,
            userId: process.env.NEXT_PUBLIC_GTM_ENV_PREFIX! + userId,
            languageId: router.locale,
          });
        } else {
          //Added GTM event for Add BookMark Click
          PushDataToGTM(GTMEvents.AddBookmark, {
            vehicleListingId:
              process.env.NEXT_PUBLIC_GTM_ASSET_ENV_PREFIX! +
              vehicle.VehicleListingId,
            userId: process.env.NEXT_PUBLIC_GTM_ENV_PREFIX! + userId,
            languageId: router.locale,
          });
          await VehicleService.addBookmark({
            VehicleListingID: vehicle.VehicleListingId!,
          });
        }
        onChangeBookmark();
        localStorage.removeItem('SignInRedirectOperation');
      } else {
        if (isFav) {
          await VehicleService.deleteBookmark({
            ProductCatalogueId: vehicle.ProductCatalogueId!,
          });
        } else {
          await VehicleService.addBookmark({
            ProductCatalogueId: vehicle.ProductCatalogueId!,
          });
        }
        onChangeBookmark();
        localStorage.removeItem('SignInRedirectOperation');
      }
    } else {
      setSignInRequired();
      const SignInRedirectOperationObj = {
        RedirectOperationType: SignInRedirectType.BookMark,
        OperationDetails: {
          vehicleId: vehicle.VehicleListingId || vehicle.ProductCatalogueId,
        },
      };
      console.log(SignInRedirectOperationObj);
      localStorage.setItem(
        'SignInRedirectOperation',
        JSON.stringify(SignInRedirectOperationObj)
      );
    }
  };

  return (
    <>
      <div
        className={`${className} flex flex-col bg-white overflow-hidden rounded-[8px] lg:rounded-none shadow-[0px_1px_8px_0px_rgba(0,0,0,0.1)]`}
      >
        <div className="relative bg-[#F8F8F8]">
          {/* <button
            className="absolute top-[9px] right-[9px] z-10"
            onClick={() => bookmarkHandler()}
          >
            <BookmarkIconNew stroke="#E9E9E9" />
          </button> */}
          {isLink ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                cleverTap.event.push(cleverName);
                isCheckOnRoadPrice
                  ? router.push(
                      `/newcars/new/${vehicle.VehicleMakeKey.toLowerCase()}/${vehicle.VehicleModelKey.toLowerCase()}`
                    )
                  : router.push(
                      `/car-details/${vehicle.VehicleMake.toLowerCase()}/${vehicle.VehicleModel.toLowerCase()}/${
                        vehicle.VehicleListingId
                      }`
                    );
              }}
            >
              <Image
                alt={'vehicle'}
                loader={customLoader}
                src={
                  vehicle?.DefaultWebImageUrlPath ||
                  vehicle?.DefaultWebImageThumbnailUrlPath ||
                  vehicle?.ThumbnailUrl ||
                  vehicle?.ImageUrlPath ||
                  (vehicle?.attributes?.DefaultWebImageThumbnailUrlPath?.data
                    ?.attributes?.url &&
                    `${process.env.NEXT_PUBLIC_CMS_API_URL}${vehicle?.attributes?.DefaultWebImageThumbnailUrlPath?.data?.attributes?.url}`) ||
                  '/images/default-car.jpg'
                }
                width={270}
                height={152}
                objectFit="cover"
                loading="lazy"
                className="aspect-[4/3]"
              />
            </div>
          ) : (
            <Image
              alt={'vehicle'}
              loading="lazy"
              loader={customLoader}
              src={
                vehicle?.DefaultWebImageUrlPath ||
                vehicle?.DefaultWebImageThumbnailUrlPath ||
                vehicle?.ThumbnailUrl ||
                vehicle?.ImageUrlPath ||
                (vehicle?.attributes?.DefaultWebImageThumbnailUrlPath?.data
                  ?.attributes?.url &&
                  `${process.env.NEXT_PUBLIC_CMS_API_URL}${vehicle?.attributes?.DefaultWebImageThumbnailUrlPath?.data?.attributes?.url}`) ||
                '/images/default-car.jpg'
              }
              // layout="fill"
              objectFit="cover"
              // className="aspect-[4/3]"
              width={270}
              height={152}
            />
          )}

          {badgeText && (
            <span
              className={`bg-white px-[6px] py-[4px] rounded-[3px] flex items-center gap-[5px] leading-[15.12px] absolute top-[12px] left-[16px] text-[12px] text-[#757575]`}
            >
              {badgeText}
            </span>
          )}

          {vehicle.IsGGMInspected == 'True' && (
            <span className="bg-[#2072FF] px-[6px] py-[4px] rounded-[3px] flex items-center gap-[5px] leading-[15.12px] absolute bottom-[11px] left-[16px] text-[12px] text-white">
              <ShieldCheckIcon className="w-[12px] h-[14px]" />
              {LabelConstants?.CERTIFIED}
            </span>
          )}
        </div>
        <div className="px-[12px] lg:px-[16px] py-[8px] lg:py-[16px]">
          {isLink ? (
            <div
              tabIndex={tabIndex}
              onClick={() => {
                cleverTap.event.push(cleverName);
                isCheckOnRoadPrice
                  ? router.push(
                      `/newcars/new/${vehicle.VehicleMakeKey.toLowerCase()}/${vehicle.VehicleModelKey.toLowerCase()}`
                    )
                  : router.push(
                      `/car-details/${vehicle.VehicleMake.toLowerCase()}/${vehicle.VehicleModel.toLowerCase()}/${
                        vehicle.VehicleListingId
                      }`
                    );
              }}
              className="cursor-pointer text-[#212121] font-medium text-[15px] lg:text-[18px] leading-[18.9px] lg:leading-[25.2px] !capitalize truncate overflow-hidden"
            >
              {`${
                vehicle && vehicle.VehicleMake
                  ? vehicle.VehicleMake.toLowerCase()
                  : vehicle?.attributes?.VehicleMake
                  ? vehicle?.attributes?.VehicleMake
                  : '-'
              }
         ${
           vehicle.VehicleModel
             ? vehicle.VehicleModel
             : vehicle?.attributes?.VehicleModel
             ? vehicle?.attributes?.VehicleModel
             : '-'
         }`}
            </div>
          ) : (
            <div
              tabIndex={tabIndex}
              className="text-[#212121] font-medium text-[15px] lg:text-[18px] leading-[18.9px] lg:leading-[25.2px] !capitalize truncate overflow-hidden"
            >
              {`${
                vehicle && vehicle.VehicleMake
                  ? vehicle.VehicleMake.toLowerCase()
                  : vehicle?.attributes?.VehicleMake
                  ? vehicle?.attributes?.VehicleMake
                  : '-'
              }
         ${
           vehicle.VehicleModel
             ? vehicle.VehicleModel
             : vehicle?.attributes?.VehicleModel
             ? vehicle?.attributes?.VehicleModel
             : '-'
         }`}
            </div>
          )}
          {isFeatures && (
            <div className="text-[#8F90A6] text-[12px] lg:text-[14px] font-normal pt-[8px] leading-[15.12px] lg:leading-[16.38px]">
              {vehicle && vehicle.Spec ? vehicle.Spec : ''}
              {vehicle && vehicle.Transmission && (
                <span> • {vehicle?.Transmission}</span>
              )}
            </div>
          )}

          {isSpecifications && (
            <div className="mt-[8px] lg:mt-[16px] flex gap-[10px]">
              {vehicle && vehicle.Mileage ? (
                <div className="text-[#757575] bg-[#F8F8F8] p-[5px] rounded-[5px] leading-[13.86px] text-[11px] font-normal">
                  {`${vehicle.Mileage ? formatNumber(vehicle.Mileage) : 0} ${
                    LabelConstants?.KM
                  }`}
                </div>
              ) : (
                <div className="invisible text-[#757575] bg-[#F8F8F8] p-[5px] rounded-[5px] leading-[13.86px] text-[11px] font-normal">
                  -
                </div>
              )}
              {vehicle && vehicle.FuelType ? (
                <div className="text-[#757575] bg-[#F8F8F8] p-[5px] rounded-[5px] leading-[13.86px] text-[11px] font-normal">
                  {vehicle.FuelType}
                </div>
              ) : (
                <div className="invisible text-[#757575] bg-[#F8F8F8] p-[5px] rounded-[5px] leading-[13.86px] text-[11px] font-normal">
                  -
                </div>
              )}
              {vehicle && vehicle.Ownership ? (
                <div className="text-[#757575] bg-[#F8F8F8] p-[5px] rounded-[5px] leading-[13.86px] text-[11px] font-normal">
                  {vehicle.Ownership} {LabelConstants?.OWNER}
                </div>
              ) : (
                <div className="invisible text-[#757575] bg-[#F8F8F8] p-[5px] rounded-[5px] leading-[13.86px] text-[11px] font-normal">
                  -
                </div>
              )}
            </div>
          )}
          {isCity && (
            <div className="mt-[12px] lg:mt-[16px] flex gap-[6px] items-center text-[#212121] leading-[13.86px] lg:leading-[16.38px] text-[11px] lg:text-[13px]">
              <LocationIcon2 className="w-[17px] h-[17px]" />
              {city?.City}
            </div>
          )}

          {isEstimatedPrice && vehicle?.lowestPrice && vehicle?.highestPrice && (
            <>
              <div className="text-[#757575] mt-[8px] lg:mt-[16px] lg:text-[13px] font-normal leading-[13.86px] lg:leading-[16.38px]">
                {LabelConstants?.ESTIMATED_PRICE}
              </div>
              <div className="font-medium text-[15px] lg:text-[20px] text-[#212121] leading-[18.9px] lg:leading-[25.2px]">
                {LabelConstants?.SAR} {vehicle?.lowestPrice || 0} -{' '}
                {vehicle?.highestPrice || 0}
              </div>
            </>
          )}

          {isSideAssistance && (
            <>
              <div className="text-[#8F90A6] mt-[8px] lg:mt-[8px] text-[13px] lg:text-[15px] leading-[16.38px] lg:leading-[18.9px] font-normal">
                {vehicle?.attributes?.AskingPrice ? (
                  <>
                    {LabelConstants?.SAR}{' '}
                    {vehicle?.attributes?.AskingPrice
                      ? formatNumber(vehicle?.attributes?.AskingPrice)
                      : ''}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mt-[10px] flex">
                {vehicle?.attributes?.Value2 && (
                  <p className="text-[#272828] bg-[#FFF2EC] px-[7px] py-[4px] rounded-[5px] leading-[13.86px] text-[11px] lg:text-[11px]">
                    {vehicle?.attributes?.Value2}{' '}
                  </p>
                )}
              </div>
            </>
          )}
        </div>

        <hr className="border-[#EBEBF0] h-[1px]" />

        <div className="px-[12px] lg:px-[16px] py-[10px] lg:py-[16px]">
          {isAskingPrice && (
            <>
              <div className="font-medium text-[15px] lg:text-[20px] text-[#212121] leading-[18.9px] lg:leading-[25.2px]">
                {LabelConstants?.SAR} {vehicle?.MonthlyEMI?.toLocaleString()}/
                <span className="text-[12px] lg:text-[15px] leading-[18.9px]">
                  {LabelConstants?.LBL_MONTH}
                </span>
              </div>
              <div className="text-[11px] lg:text-[12px] text-[#8F90A6] leading-[13.86px] lg:leading-[16.38px]">
                {LabelConstants?.SAR}{' '}
                {`${
                  vehicle.AskingPrice ? formatNumber(vehicle.AskingPrice) : '-'
                }`}
              </div>
            </>
          )}

          {isCheckOnRoadPrice && (
            <div
              className="font-medium text-[13px] lg:text-[20px] text-[#E02E01] leading-[16.38px] lg:leading-[25.2px] cursor-pointer"
              onClick={() => {
                router.push(
                  `/newcars/new/${vehicle.VehicleMakeKey.toLowerCase()}/${vehicle.VehicleModelKey.toLowerCase()}`
                );
              }}
            >
              {LabelConstants?.EXPLORE_MORE}
            </div>
          )}

          {isNotified && (
            <div
              className="font-medium text-[13px] lg:text-[20px] text-[#E02E01] leading-[16.38px] lg:leading-[25.2px] cursor-pointer"
              onClick={() => {
                setVehicle(vehicle);
              }}
            >
              {LabelConstants?.GET_NOTIFIED}
            </div>
          )}

          {isEmiStartingFrom && (
            <>
              <div className="font-medium text-[11px] lg:text-[13px] text-[#8F90A6] leading-[13.86px] lg:leading-[16.38px]">
                {LabelConstants?.EMI_STARTING_FROM}
              </div>
              <div className="mt-[4px] lg:mt-[12px] font-medium text-[14px] lg:text-[20px] text-[#212121] leading-[17.64px] lg:leading-[25.2px]">
                {LabelConstants?.SAR}{' '}
                {vehicle?.MonthlyEMI?.toLocaleString() ||
                  formatNumber(vehicle?.attributes?.EMI)}
                /
                <span className="text-[12px] lg:text-[15px] leading-[15.12px] lg:leading-[18.9px]">
                  {LabelConstants?.LBL_MONTH}
                </span>
              </div>
              {isBudget ? (
                <div className="mt-[6px] text-[13px] lg:text-[16px] font-medium leading-[20.16px] text-[#212121]">
                  {LabelConstants?.SAR}{' '}
                  {`${
                    vehicle.AskingPrice
                      ? formatNumber(vehicle.AskingPrice)
                      : '-'
                  }`}
                  {/* <span className="ms-1 me-1 text-[11px] lg:ext-[14px] text-[#757575] line-through">
                  {' '}
                  {t(LabelConstants.SAR)} 1,30,800
                </span> */}
                </div>
              ) : (
                <div className="mt-[4px] lg:mt-[6px] text-[12px] lg:text-[13px] font-medium leading-[15.12px] lg:leading-[16.38px] text-[#212121]">
                  {vehicle?.attributes?.Value1 && vehicle?.attributes?.Value1}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VehicleCard;
