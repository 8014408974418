// CarCard.tsx
import React, { useEffect } from 'react';

interface Vehicle {
  index: Number;
  id: string;
}

const ShimmerVehicleCard: React.FC<Vehicle> = ({ id, index }) => {
  return (
    <div
      key={id + index}
      className={` bg-white overflow-hidden rounded-[8px] lg:rounded-none shadow-[0_1px_8px_0px_rgba(0,0,0,0.1)]  h-[300px]`}
    ></div>
  );
};

export default ShimmerVehicleCard;
