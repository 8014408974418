import React, { useEffect, useMemo, useState } from 'react';
import {
  BookmarkResponse,
  City,
  SearchAllResultItem,
  SearchResultItem,
} from '../../types/models';
import VehicleCard from './VehicleCard';
import { ChevronLeftIcon } from '../icons';
import { Swiper } from 'swiper';
import { Navigation } from 'swiper/modules';
import { useTranslation } from 'next-i18next';
import ShimmerVehicleCard from './ShimmerVehicleCard';
import { VehicleService } from '../../helpers/services';
import SignInModal from '../common/SignInModal';

Swiper.use([Navigation]);

interface PropType {
  id?: string;
  label?: string;
  cities?: Array<City>;
  silderData: Array<any>;
  className?: string;
  isFeatures?: boolean;
  isSpecifications?: boolean;
  isEstimatedPrice?: boolean;
  isAskingPrice?: boolean;
  isCity?: boolean;
  isCheckOnRoadPrice?: boolean;
  badgeText?: string;
  isSideAssistance?: boolean;
  description?: string;
  isEmiStartingFrom?: boolean;
  isNotified?: boolean;
  isBudget?: boolean;
  isLink?: boolean;
  cleverName?: string;
  isLoading?: boolean;
  setVehicle?: any;
  height?: string;
  width?: string;
  tabIndex?: number;
}

const SwiperCarousel: React.FC<PropType> = ({
  id = '',
  label = '',
  cities = [],
  silderData,
  className,
  isFeatures,
  isSpecifications,
  isEstimatedPrice,
  isAskingPrice,
  isCity,
  isCheckOnRoadPrice,
  badgeText,
  isSideAssistance,
  description,
  isEmiStartingFrom,
  isNotified,
  isBudget,
  isLink,
  cleverName,
  isLoading,
  setVehicle,
  width,
  height,
  tabIndex = 0,
}) => {
  const { t, i18n } = useTranslation();
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [bookmarks, setBookmarks] = useState<BookmarkResponse>({
    UserId: 0,
    ProductCatalogueIds: [],
    VehicleListingIds: [],
  });

  useEffect(() => {
    var swiperBanner = new Swiper(`.${id}-swiper`, {
      spaceBetween: 24,
      slidesPerView: 4,
      slidesPerGroup: 1,
      // slidesOffsetBefore: 20,
      // preventInteractionOnTransition: true,
      // modules: [Navigation],
      navigation: {
        nextEl: `.${id}-next-arrow`,
        prevEl: `.${id}-prev-arrow`,
        disabledClass: 'swiper-button-disabled',
      },
      watchOverflow: true,
      breakpoints: {
        0: {
          slidesPerView: 1.5,
          // width: 340,
        },
        525: {
          slidesPerView: 2,
        },
        660: {
          slidesPerView: 2.5,
        },
        751: {
          slidesPerView: 3,
        },
        1400: {
          slidesPerView: 4,
        },
      },
    });
    return () => {
      if (swiperBanner) {
        swiperBanner.destroy();
      }
    };
  }, [i18n.language, silderData]);

  const isRTL = useMemo(() => {
    return i18n.language === 'ar';
  }, [i18n.language]);

  const handleChangeBookmark = async () => {
    const result = await VehicleService.fetchBookmark();
    setBookmarks(result);
  };

  return (
    <>
      <div className="max-w-full theme-v1">
        {label && (
          <h1
            className={`text-[20px] lg:text-[34px] leading-[25.2px] lg:leading-[42.84px] text-[#212121] ${
              description ? 'mb-[6px] lg:mb-[14px]' : 'mb-[20px] md:mb-[36px]'
            } font-semibold`}
          >
            {label}
          </h1>
        )}
        {description && (
          <p className="mb-[20px] md:mb-[36px] text-[12px] sm:text-[18px] font-medium text-[#666262] leading-[16.38px] lg:leading-[25.2px]">
            {description}
          </p>
        )}
        {/* {isLoading && (
        <div className="flex gap-10">
          {[1, 2, 3, 4].map((index) => (
            <ShimmerVehicleCard
              id="top-rated-id"
              index={index}
              key={'top-rated-id' + index}
            />
          ))}
        </div>
      )} */}
        <div className="relative">
          <div className="swiper !p-1">
            <div
              className={`${id}-swiper`}
              dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            >
              <div className="swiper-wrapper">
                {isLoading &&
                  [1, 2, 3, 4].map((index) => (
                    <div
                      className="swiper-slide relative cursor-grab"
                      key={id + index}
                      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    >
                      <ShimmerVehicleCard
                        id="top-rated-id"
                        index={index}
                        key={'top-rated-id' + index}
                      />
                    </div>
                  ))}
                {silderData.length > 0 &&
                  silderData.map((item, index) => (
                    <div
                      className={`swiper-slide relative cursor-grab`}
                      key={id + index}
                      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    >
                      <VehicleCard
                        vehicle={item}
                        className={className}
                        isFeatures={isFeatures}
                        isSpecifications={isSpecifications}
                        isEstimatedPrice={isEstimatedPrice}
                        isAskingPrice={isAskingPrice}
                        isCity={isCity}
                        isCheckOnRoadPrice={isCheckOnRoadPrice}
                        badgeText={badgeText}
                        isSideAssistance={isSideAssistance}
                        isEmiStartingFrom={isEmiStartingFrom}
                        isNotified={isNotified}
                        isBudget={isBudget}
                        isLink={isLink}
                        city={cities.find(
                          (x) => x.CityId === parseInt(item.CityId)
                        )}
                        cleverName={cleverName}
                        setVehicle={setVehicle}
                        bookmarkIds={bookmarks.VehicleListingIds}
                        productCatalogueBookmarkIds={
                          bookmarks.ProductCatalogueIds
                        }
                        setSignInRequired={() => setShowLogin(true)}
                        onChangeBookmark={handleChangeBookmark}
                        height={height}
                        tabIndex={tabIndex + index}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/* <Swiper
          slidesPerView={4}
          spaceBetween={20}
          className="!p-1"
          breakpoints={{
            0: {
              slidesPerView: 1.5,
            },
            639: {
              slidesPerView: 2,
            },
            865: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
          }}
          modules={[Navigation]}
          navigation={{
            nextEl: `.${id}-next-arrow`,
            prevEl: `.${id}-prev-arrow`,
            disabledClass: 'swiper-button-disabled',
          }}
        >
          {silderData.map((item, index) => (
            <SwiperSlide
              key={index}
              dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            >
              <VehicleCard
                vehicle={item}
                className={className}
                isFeatures={isFeatures}
                isSpecifications={isSpecifications}
                isEstimatedPrice={isEstimatedPrice}
                isAskingPrice={isAskingPrice}
                isCity={isCity}
                isCheckOnRoadPrice={isCheckOnRoadPrice}
                badgeText={badgeText}
                isSideAssistance={isSideAssistance}
                isEmiStartingFrom={isEmiStartingFrom}
                isNotified={isNotified}
                isBudget={isBudget}
                city={cities.find((x) => x.CityId === parseInt(item.CityId))}
              />
            </SwiperSlide>
          ))}
        </Swiper> */}
          <div className="opacity-0 sm:opacity-100">
            <button
              tabIndex={tabIndex + 50}
              className={`${id}-prev-arrow absolute z-10 top-[45%] ${
                isRTL ? 'right-[0px] md:-right-[12px]' : '-left-[12px]'
              }`}
              id="previd"
              aria-label="swiperpprev"
            >
              <ChevronLeftIcon
                className={`w-8 h-8 ${
                  isRTL ? 'rotate-180' : ''
                } bg-white hover:bg-white p-1 pr-2 rounded-full shadow-[0px_-2px_6px_0px_#00000024]`}
              />
            </button>
            <button
              tabIndex={tabIndex + 51}
              className={`${id}-next-arrow absolute z-10 top-[45%]  ${
                isRTL ? '-left-[12px]' : 'right-[0px] md:-right-[12px]'
              }`}
              id="nextid"
              aria-label="swiperpnext"
            >
              <ChevronLeftIcon
                className={`w-8 h-8 ${
                  isRTL ? '' : 'rotate-180'
                } bg-white p-1 pr-2 rounded-full shadow-[0px_2px_6px_0px_#00000024]`}
              />
            </button>
          </div>
        </div>
      </div>
      {/* Sign In Modal */}
      {showLogin && (
        <SignInModal
          show={showLogin}
          onClose={() => {
            setShowLogin(false);
            localStorage.removeItem('SignInRedirectOperation');
          }}
        />
      )}
    </>
  );
};

export default SwiperCarousel;
