import { useTranslation } from 'next-i18next';
import { ChevronLeftIcon } from '../icons';
import Link from 'next/link';

type PropType = {
  label: string;
  link: string;
  tabIndex?: number;
};

const ViewAll: React.FC<PropType> = (props) => {
  const { label, link, tabIndex } = props;
  const { i18n } = useTranslation();
  return (
    <Link href={link}>
      <p
        tabIndex={tabIndex}
        className="text-[#4C0055] mt-[16px] lg:mt-[28px] text-[13px] lg:text-[20px] leading-[16.38px] lg:leading-[25.2px] font-normal flex items-center gap-2 cursor-pointer"
      >
        {label}
        <ChevronLeftIcon
          className={`w-6 h-6 mt-1 ${
            i18n.language === 'ar' ? '' : 'rotate-180'
          }`}
        />
      </p>
    </Link>
  );
};

export default ViewAll;
